import {
  SET_CATEGORIES,
  SET_CATEGORY,
  CLEAR_CATEGORIES,
  CLEAR_CATEGORY,
  ADD_CATEGORY,
  UPDATE_CATEGORY,
  REMOVE_CATEGORY,
} from './ActionTypes';

export const category = (state = {}, category) => {
  switch (category.type) {
    case SET_CATEGORY:
      return category.payload;
    case UPDATE_CATEGORY:
      if (
        state &&
        state.id &&
        state.id.toString() !== category.payload.id.toString()
      ) {
        return state;
      }

      // Otherwise, this is the one we want - return an updated value
      return {
        ...state,
        ...category.payload,
      };
    case CLEAR_CATEGORY:
      return {};
    default:
      return state;
  }
};

export default (state = [], category) => {
  switch (category.type) {
    case SET_CATEGORIES:
      return category.payload;
    case ADD_CATEGORY:
      return [...state, category.payload];
    case UPDATE_CATEGORY:
      return state.map((item) => {
        if (
          item &&
          item.id &&
          item.id.toString() !== category.payload.id.toString()
        ) {
          return item;
        }

        // Otherwise, this is the one we want - return an updated value
        return {
          ...item,
          ...category.payload,
        };
      });
    case REMOVE_CATEGORY:
      return state.filter((item) => {
        return item.id !== category.payload.id;
      });
    case CLEAR_CATEGORIES:
      return [];
    default:
      return state;
  }
};
