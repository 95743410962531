import React from 'react';
import { Box, Container, View, Title } from '@common/components';
import { connect } from 'react-redux';
import { Button, Card, Col, Row, message } from 'antd';
import BankStatementResults from '@containers/BankStatement/Fragments/Result';
import MemberResults from '@containers/Member/Fragments/Result';
import TithelyResults from '@containers/Tithely/Fragments/Result';
import {
  setBankStatements,
  parseBankStatementCSV,
} from '@resources/BankStatement/Actions';
import { setMembers, parseMembersCSV } from '@resources/Member/Actions';
import { setTithelies, parseTitheliesCSV } from '@resources/Tithely/Actions';
import { CSVReader } from 'react-papaparse';
import { CloseCircleTwoTone, CheckCircleTwoTone } from '@ant-design/icons';
import dayjs from 'dayjs';

const Dashboard = ({
  history,
  members,
  bankStatements,
  tithelies,
  setBankStatements,
  setMembers,
  setTithelies,
}) => {
  const checkBankStatementData = (data, file) => {
    const day =
      file.name.split('_').length > 1 &&
      file.name.split('_')[2].split('.').length > 0 &&
      file.name
        .split('_')[2]
        .split('.')[0]
        .substr(6, 2);
    const week =
      day === '07'
        ? 1
        : day === '14'
        ? 2
        : day === '21'
        ? 3
        : day === '29' || day === '30' || day === '31'
        ? 5
        : 4;
    const workdate = dayjs(
      file.name.split('_').length > 1 &&
        file.name.split('_')[2].split('.').length > 0 &&
        file.name.split('_')[2].split('.')[0],
      'YYYYMMDD'
    )
      .format('DDMMYY')
      .toString();
    setBankStatements(parseBankStatementCSV(data, week, workdate));
    message.success('Bank statement has been successfully uploaded');
  };

  const checkMemberData = (data) => {
    setMembers(parseMembersCSV(data));

    message.success('Members data has been successfully uploaded');
  };

  const checkTithelyData = (data, file) => {
    const workdate = dayjs(
      file.name.replace('Tithely ', '').replace('.csv', ''),
      'YYYYMMDD'
    )
      .format('DDMMYY')
      .toString();

    setTithelies(parseTitheliesCSV(data, workdate));

    message.success('Tithely statement has been successfully uploaded');
  };

  return (
    <View>
      <Container>
        <Box flex>
          <Title>Dashboard</Title>

          <Title fontSize={20}>Data Upload</Title>
          <Row gutter={20}>
            <Col md={6}>
              <Card title="Members" bordered={true}>
                {members && members.length > 0 ? (
                  <>
                    <MemberResults />
                    <Box alignItems="flex-end" flex textAlign="right">
                      <Button
                        type="primary"
                        onClick={() => {
                          history.push('/members');
                        }}
                      >
                        View Details &raquo;
                      </Button>
                    </Box>
                  </>
                ) : (
                  <>
                    You have not added your member data.
                    <br />
                    <br />
                    <Box alignItems="flex-end" flex>
                      <Box fontSize={10} textAlign="center">
                        CLICK OR DRAG & DROP FILE
                      </Box>
                      <CSVReader
                        onDrop={(data, file) => {
                          checkMemberData(data);
                        }}
                      >
                        Upload Members CSV
                      </CSVReader>
                    </Box>
                  </>
                )}
              </Card>
              <br />
              <br />
            </Col>

            <Col md={6}>
              <Card title="Bank Statement" bordered={true}>
                {bankStatements && bankStatements.length > 0 ? (
                  <>
                    <BankStatementResults />

                    <Box alignItems="flex-end" flex textAlign="right">
                      <Button
                        type="primary"
                        onClick={() => {
                          history.push('/bank-statements');
                        }}
                      >
                        View Details &raquo;
                      </Button>
                    </Box>
                  </>
                ) : (
                  <>
                    You have not added the bank statement.
                    <br />
                    <br />
                    <Box alignItems="flex-end" flex>
                      <Box fontSize={10} textAlign="center">
                        CLICK OR DRAG & DROP FILE
                      </Box>
                      <CSVReader
                        onDrop={(data, file) =>
                          checkBankStatementData(data, file)
                        }
                      >
                        Upload Bank Statement CSV
                      </CSVReader>
                    </Box>
                  </>
                )}
              </Card>
              <br />
              <br />
            </Col>

            <Col md={6}>
              <Card title="Tithely" bordered={true}>
                {tithelies && tithelies.length > 0 ? (
                  <>
                    <TithelyResults />

                    <Box alignItems="flex-end" flex textAlign="right">
                      <Button
                        type="primary"
                        onClick={() => {
                          history.push('/tithelies');
                        }}
                      >
                        View Details &raquo;
                      </Button>
                    </Box>
                  </>
                ) : (
                  <>
                    You have not uploaded the Tithely statement.
                    <br />
                    <br />
                    <Box alignItems="flex-end" flex>
                      <Box fontSize={10} textAlign="center">
                        CLICK OR DRAG & DROP FILE
                      </Box>
                      <CSVReader
                        onDrop={(data, file) => checkTithelyData(data, file)}
                      >
                        Upload Tithely CSV
                      </CSVReader>
                    </Box>
                  </>
                )}
              </Card>
              <br />
              <br />
            </Col>

            <Col md={6}>
              <Card title="Extractor" bordered={true}>
                <Box display="flex" mb={20}>
                  <Box width={30}>
                    {members &&
                    members.length > 0 &&
                    bankStatements &&
                    bankStatements.length > 0 ? (
                      <CheckCircleTwoTone
                        style={{ fontSize: 20 }}
                        twoToneColor="#52c41a"
                      />
                    ) : (
                      <CloseCircleTwoTone
                        style={{ fontSize: 20 }}
                        twoToneColor="#FF0000"
                      />
                    )}
                  </Box>
                  <Box flex={1}>Bank Statement</Box>
                </Box>

                <Box display="flex" mb={20}>
                  <Box width={30}>
                    {members &&
                    members.length > 0 &&
                    tithelies &&
                    tithelies.length > 0 ? (
                      <CheckCircleTwoTone
                        style={{ fontSize: 20 }}
                        twoToneColor="#52c41a"
                      />
                    ) : (
                      <CloseCircleTwoTone
                        style={{ fontSize: 20 }}
                        twoToneColor="#FF0000"
                      />
                    )}
                  </Box>
                  <Box flex={1}>Tithely</Box>
                </Box>

                <br />
                <br />
                <Box alignItems="flex-end" flex textAlign="right">
                  <Button
                    type="primary"
                    disabled={
                      !(
                        members &&
                        members.length > 0 &&
                        ((bankStatements && bankStatements.length > 0) ||
                          (tithelies && tithelies.length > 0))
                      )
                    }
                    onClick={() => {
                      history.push('/extraction');
                    }}
                  >
                    Extract &raquo;
                  </Button>
                </Box>
              </Card>
              <br />
              <br />
            </Col>
          </Row>

          <Title fontSize={20}>Manage Resources</Title>
          <Row gutter={20}>
            <Col md={6}>
              <Card title="Categories" bordered={true}>
                Manage your categories here. You can add, update and delete
                categories
                <br />
                <br />
                <Box alignItems="flex-end" flex textAlign="right">
                  <Button
                    type="primary"
                    onClick={() => {
                      history.push('/categories');
                    }}
                  >
                    Manage Categories
                  </Button>
                </Box>
              </Card>
              <br />
              <br />
            </Col>
            <Col md={6}>
              <Card title="Exceptions" bordered={true}>
                Manage your exceptions here. You can add, update and delete
                exceptions
                <br />
                <br />
                <Box alignItems="flex-end" flex textAlign="right">
                  <Button
                    type="primary"
                    onClick={() => {
                      history.push('/exceptions');
                    }}
                  >
                    Manage Exceptions
                  </Button>
                </Box>
              </Card>
              <br />
              <br />
            </Col>
          </Row>
        </Box>
      </Container>
    </View>
  );
};

const mapStateToProps = ({ members, bankStatements, tithelies }) => ({
  members,
  bankStatements,
  tithelies,
});

export default connect(mapStateToProps, {
  setBankStatements,
  setMembers,
  setTithelies,
})(Dashboard);
