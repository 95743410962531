import React, { useEffect } from 'react';
import { Container, View, Title } from '@common/components';
import { Form, Input, Button, message } from 'antd';
import { Link } from 'react-router-dom';
import {
  fetchCategory,
  createCategory,
  updateCategory,
  clearCategory,
} from '@resources/Category/Actions';
import { connect } from 'react-redux';

const CategoryForm = ({
  match,
  history,
  category,
  fetchCategory,
  createCategory,
  updateCategory,
  clearCategory,
}) => {
  const [form] = Form.useForm();
  const fetchData = async () => {
    match.params.id && (await fetchCategory(match.params.id));
  };

  useEffect(() => {
    fetchData();

    return () => {
      clearCategory();
    };
  }, []);

  const handleSubmit = async (values) => {
    match.params.id
      ? await updateCategory(match.params.id, values)
      : await createCategory(values);

    message.success(
      match.params.id
        ? 'An existing Category entry has been successfully updated'
        : 'A new Category entry has been successfully created',
      6
    );
    history.push('/categories');
  };

  useEffect(() => {
    form.setFieldsValue(category);
  }, [category]);

  return (
    <View>
      <Container>
        <Title>{match.params.id ? 'Update Category' : 'New Category'}</Title>
        <Form form={form} layout="vertical" onFinish={handleSubmit}>
          <Form.Item
            label="Title"
            name="title"
            rules={[
              {
                required: true,
                message: 'Please input title',
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item label="Description" name="description">
            <Input />
          </Form.Item>

          <Form.Item>
            <Button type="secondary">
              <Link to="/categories">Cancel</Link>
            </Button>
            &nbsp;&nbsp;
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button"
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Container>
    </View>
  );
};

const mapStateToProps = ({ category, inputs }) => ({
  category,
  inputs,
});

export default connect(mapStateToProps, {
  fetchCategory,
  createCategory,
  updateCategory,
  clearCategory,
})(CategoryForm);
