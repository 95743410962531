import {
  SET_EXCEPTIONS,
  SET_EXCEPTION,
  ADD_EXCEPTION,
  ADD_EXCEPTIONS,
  UPDATE_EXCEPTION,
  REMOVE_EXCEPTION,
  CLEAR_EXCEPTIONS,
  CLEAR_EXCEPTION,
} from './ActionTypes';
import db from '@config/firestore';
import {
  collection,
  doc,
  addDoc,
  setDoc,
  getDoc,
  getDocs,
  deleteDoc,
} from 'firebase/firestore/lite';

const setExceptions = (exceptions) => (dispatch) => {
  dispatch({
    type: SET_EXCEPTIONS,
    payload: exceptions,
  });
};

const setException = (exception) => (dispatch) => {
  dispatch({
    type: SET_EXCEPTION,
    payload: exception,
  });
};

const addException = (exception) => (dispatch) => {
  dispatch({
    type: ADD_EXCEPTION,
    payload: exception,
  });
};

const addExceptions = (exception) => (dispatch) => {
  dispatch({
    type: ADD_EXCEPTIONS,
    payload: exception,
  });
};

const clearExceptions = () => (dispatch) => {
  dispatch({
    type: CLEAR_EXCEPTIONS,
  });
};

const clearException = () => (dispatch) => {
  dispatch({
    type: CLEAR_EXCEPTION,
  });
};

const fetchExceptions = () => async (dispatch) => {
  let exceptions = [];
  try {
    const exceptionsCol = collection(db, 'exceptions');
    const exceptionSnapshot = await getDocs(exceptionsCol);
    exceptions = exceptionSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    dispatch({
      type: SET_EXCEPTIONS,
      payload: exceptions,
    });

    return Promise.resolve(exceptions);
  } catch (e) {
    console.log('fetchExceptions', e);
    return Promise.reject(e);
  }
};

const fetchException = (id) => async (dispatch) => {
  try {
    const catRef = doc(db, 'exceptions', id);
    const exceptionSnapshot = await getDoc(catRef);

    dispatch({
      type: SET_EXCEPTION,
      payload: exceptionSnapshot.data(),
    });

    return Promise.resolve(exceptionSnapshot);
  } catch (e) {
    console.log('fetchExceptions', e);
    return Promise.reject(e);
  }
};

const createException = (data) => async (dispatch) => {
  try {
    const exceptionsCol = collection(db, 'exceptions');
    const exception = await addDoc(exceptionsCol, data);

    dispatch({
      type: ADD_EXCEPTION,
      payload: { ...data, id: exception.id },
    });

    return Promise.resolve(exception);
  } catch (e) {
    return Promise.reject(e);
  }
};

const updateException = (id, data) => async (dispatch) => {
  try {
    const exception = await setDoc(doc(db, 'exceptions', id), data);

    console.log(exception, { ...data, id });

    dispatch({
      type: UPDATE_EXCEPTION,
      payload: { ...data, id },
    });
    return Promise.resolve(exception);
  } catch (e) {
    return Promise.reject(e);
  }
};

const deleteException = (exception) => async (dispatch) => {
  try {
    const exceptionDoc = doc(db, 'exceptions', exception.id);
    await deleteDoc(exceptionDoc);

    dispatch({
      type: REMOVE_EXCEPTION,
      payload: exception,
    });

    return Promise.resolve(exception);
  } catch (e) {
    return Promise.reject(e);
  }
};

export {
  setExceptions,
  setException,
  addException,
  addExceptions,
  deleteException,
  updateException,
  clearExceptions,
  clearException,
  createException,
  fetchException,
  fetchExceptions,
};
