import {
  SET_MEMBERS,
  SET_MEMBER,
  CLEAR_MEMBERS,
  CLEAR_MEMBER,
  ADD_MEMBER,
  UPDATE_MEMBER,
  REMOVE_MEMBER,
} from './ActionTypes';

export const member = (state = {}, member) => {
  switch (member.type) {
    case SET_MEMBER:
      return member.payload;
    case UPDATE_MEMBER:
      if (state._id.toString() !== member.payload._id.toString()) {
        return state;
      }

      // Otherwise, this is the one we want - return an updated value
      return {
        ...state,
        ...member.payload,
      };
    case CLEAR_MEMBER:
      return {};
    default:
      return state;
  }
};

export default (state = [], member) => {
  switch (member.type) {
    case SET_MEMBERS:
      return member.payload;
    case ADD_MEMBER:
      return [...state, member.payload];
    case UPDATE_MEMBER:
      return state.map((item) => {
        if (item._id !== member.payload._id) {
          return item;
        }

        // Otherwise, this is the one we want - return an updated value
        return {
          ...item,
          ...member.payload,
        };
      });
    case REMOVE_MEMBER:
      return state.map((item) => {
        if (item._id !== member.payload._id) {
          return item;
        }

        // Otherwise, this is the one we want - return an updated value
        return {
          ...item,
        };
      });
    case CLEAR_MEMBERS:
      return [];
    default:
      return state;
  }
};
