import React, { useEffect } from 'react';
import { Container, View, Title } from '@common/components';
import { Form, Input, Button, message } from 'antd';
import { Link } from 'react-router-dom';
import {
  fetchException,
  createException,
  updateException,
  clearException,
} from '@resources/Exception/Actions';
import { connect } from 'react-redux';

const ExceptionForm = ({
  match,
  history,
  exception,
  fetchException,
  createException,
  updateException,
  clearException,
}) => {
  const [form] = Form.useForm();
  const fetchData = async () => {
    match.params.id && (await fetchException(match.params.id));
  };

  useEffect(() => {
    fetchData();

    return () => {
      clearException();
    };
  }, []);

  const handleSubmit = async (values) => {
    match.params.id
      ? await updateException(match.params.id, values)
      : await createException(values);

    message.success(
      match.params.id
        ? 'An existing Exception entry has been successfully updated'
        : 'A new Exception entry has been successfully created',
      6
    );
    history.push('/exceptions');
  };

  useEffect(() => {
    form.setFieldsValue(exception);
  }, [exception]);

  return (
    <View>
      <Container>
        <Title>{match.params.id ? 'Update Exception' : 'New Exception'}</Title>
        <Form form={form} layout="vertical" onFinish={handleSubmit}>
          <Form.Item
            label="Name"
            name="name"
            rules={[
              {
                required: true,
                message: 'Please input name',
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Action"
            name="action"
            rules={[
              {
                required: true,
                message: 'Please input action',
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item>
            <Button type="secondary">
              <Link to="/exceptions">Cancel</Link>
            </Button>
            &nbsp;&nbsp;
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button"
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Container>
    </View>
  );
};

const mapStateToProps = ({ exception, inputs }) => ({
  exception,
  inputs,
});

export default connect(mapStateToProps, {
  fetchException,
  createException,
  updateException,
  clearException,
})(ExceptionForm);
