import {
  SET_MEMBERS,
  SET_MEMBER,
  ADD_MEMBER,
  ADD_MEMBERS,
  CLEAR_MEMBERS,
} from './ActionTypes';

const setMembers = (members) => (dispatch) => {
  dispatch({
    type: SET_MEMBERS,
    payload: members,
  });
};

const setMember = (member) => (dispatch) => {
  dispatch({
    type: SET_MEMBER,
    payload: member,
  });
};

const addMember = (member) => (dispatch) => {
  dispatch({
    type: ADD_MEMBER,
    payload: member,
  });
};

const addMembers = (member) => (dispatch) => {
  dispatch({
    type: ADD_MEMBERS,
    payload: member,
  });
};

const clearMembers = () => (dispatch) => {
  dispatch({
    type: CLEAR_MEMBERS,
  });
};

const AdultConversion = [
  'west center',
  'northeast center',
  'east center',
  'north center',
  'central 1',
  'central 2',
  'central 3',
  'central 4',
  'central 5',
  'central',
  'business',
  'adult northeast',
  'adult north',
  'east adult',
];

const HopeKidsConversion = [
  'hopekids central east',
  'hopekids central west',
  'hopekids leaders',
  'hopekids north',
  'hopekids northeast',
  'hopekids',
];

const InternationalConversion = ['indo', 'viet'];

const MandarinConversion = ['mandarin adult'];

const parseMembersCSV = (memberCSV) => {
  const data = [];

  const unique = (a) => {
    var seen = {};
    var out = [];
    var len = a.length;
    var j = 0;
    for (var i = 0; i < len; i++) {
      var item = a[i];
      if (seen[item.data[2]] !== 1) {
        seen[item.data[2]] = 1;
        out[j++] = item;
      }
    }

    return out;

    // var seenName = {};

    // var outMore = [];

    // var l = 0;
    // for (var k = 0; k < j; k++) {
    //   var itemName = out[k];
    //   if (seenName[`${itemName.data[0]} ${itemName.data[1]}`] !== 1) {
    //     seenName[`${itemName.data[0]} ${itemName.data[1]}`] = 1;
    //     outMore[l++] = itemName;
    //   }
    // }
    // return outMore;
  };

  memberCSV &&
    memberCSV.length > 0 &&
    unique(memberCSV).forEach((item, index) => {
      if (index > 0) {
        const cong = item.data[3]
          ? item.data[3].replace(' Centre', '')
          : item.data[3];

        const region =
          item.data[5] &&
          AdultConversion.indexOf(
            item.data[5]
              .toLowerCase()
              .replace('axis ', '')
              .replace('nec ', '')
              .replace('wec ', '')
          ) > -1
            ? 'Adult'
            : item.data[5] &&
              HopeKidsConversion.indexOf(
                item.data[5]
                  .toLowerCase()
                  .replace('axis ', '')
                  .replace('nec ', '')
                  .replace('wec ', '')
              ) > -1
            ? 'Hopekids'
            : item.data[5] &&
              InternationalConversion.indexOf(
                item.data[5]
                  .toLowerCase()
                  .replace('axis ', '')
                  .replace('nec ', '')
                  .replace('wec ', '')
              ) > -1
            ? 'International'
            : item.data[5] &&
              MandarinConversion.indexOf(
                item.data[5]
                  .toLowerCase()
                  .replace('axis ', '')
                  .replace('nec ', '')
                  .replace('wec ', '')
              ) > -1
            ? 'Mandarin'
            : item.data[5] &&
              item.data[5]
                .replace('Axis ', '')
                .replace('NEC ', '')
                .replace('WEC ', '');

        data.push({
          lastName: item.data[0],
          firstName: item.data[1],
          memberID: item.data[2],
          center: item.data[3],
          congregation: cong,
          region: region,
          district: item.data[6],
          subDistrict: item.data[7],
          lifeGroup: item.data[8],
        });
      }
    });

  return data;
};

export {
  setMembers,
  setMember,
  addMember,
  addMembers,
  clearMembers,
  parseMembersCSV,
};
