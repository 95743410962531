import { SET_USER } from './ActionTypes';
import db from '@config/firestore';
import { collection, getDocs, query, where } from 'firebase/firestore/lite';

const login = (name, password) => async (dispatch) => {
  try {
    const userRef = collection(db, 'users');

    const q = query(
      userRef,
      where('name', '==', name),
      where('password', '==', password)
    );
    const querySnapshot = await getDocs(q);

    if (querySnapshot && querySnapshot.docs && querySnapshot.docs.length > 0) {
      let user;
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots

        user = { id: doc.id, ...doc.data() };

        dispatch({
          type: SET_USER,
          payload: { id: doc.id, ...doc.data() },
        });
      });

      return Promise.resolve(user);
    } else {
      return Promise.reject({ message: 'Incorrect login credentials' });
    }
  } catch (e) {
    console.log('fetchCategories', e);
    return Promise.reject(e);
  }
};

export { login };
