import {
  SET_BANK_STATEMENTS,
  SET_BANK_STATEMENT,
  CLEAR_BANK_STATEMENTS,
  CLEAR_BANK_STATEMENT,
  ADD_BANK_STATEMENT,
  UPDATE_BANK_STATEMENT,
  REMOVE_BANK_STATEMENT,
} from './ActionTypes';

export const bankStatement = (state = {}, bankStatement) => {
  switch (bankStatement.type) {
    case SET_BANK_STATEMENT:
      return bankStatement.payload;
    case UPDATE_BANK_STATEMENT:
      if (state._id.toString() !== bankStatement.payload._id.toString()) {
        return state;
      }

      // Otherwise, this is the one we want - return an updated value
      return {
        ...state,
        ...bankStatement.payload,
      };
    case CLEAR_BANK_STATEMENT:
      return {};
    default:
      return state;
  }
};

export default (state = [], bankStatement) => {
  switch (bankStatement.type) {
    case SET_BANK_STATEMENTS:
      return bankStatement.payload;
    case ADD_BANK_STATEMENT:
      return [...state, bankStatement.payload];
    case UPDATE_BANK_STATEMENT:
      return state.map((item) => {
        if (item._id !== bankStatement.payload._id) {
          return item;
        }

        // Otherwise, this is the one we want - return an updated value
        return {
          ...item,
          ...bankStatement.payload,
        };
      });
    case REMOVE_BANK_STATEMENT:
      return state.map((item) => {
        if (item._id !== bankStatement.payload._id) {
          return item;
        }

        // Otherwise, this is the one we want - return an updated value
        return {
          ...item,
        };
      });
    case CLEAR_BANK_STATEMENTS:
      return [];
    default:
      return state;
  }
};
