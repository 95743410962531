import React from 'react';
import { Box, Text } from '@common/components';
import { connect } from 'react-redux';
import { Col, Row } from 'antd';

const MemberResult = ({ history, cols = 24, members, bankStatements }) => {
  return (
    <Box flex>
      <Row>
        <Col xs={cols}>
          <Text fontSize={10} html="TOTAL MEMBERS" />
          {members ? members.length : 0}
          <br />
          <br />
        </Col>
      </Row>
    </Box>
  );
};

const mapStateToProps = ({ members, bankStatements }) => ({
  members,
  bankStatements,
});

export default connect(mapStateToProps, {})(MemberResult);
