import React, { useCallback, useEffect, useState } from 'react';
import { Box, Container, View, Title } from '@common/components';
import {
  setBankStatements,
  clearBankStatements,
  parseBankStatementCSV,
} from '@resources/BankStatement/Actions';
import { connect } from 'react-redux';
import { CSVReader } from 'react-papaparse';
import { Button, Table } from 'antd';
import BankStatementResult from './Fragments/Result';
import { getColumnSearchProps, getColumnSortProps } from '@common/utils/table';
import dayjs from 'dayjs';

const BankStatement = ({
  bankStatements,
  setBankStatements,
  clearBankStatements,
}) => {
  const [sortedInfo, setSortedInfo] = useState({});
  const handleChange = useCallback(
    (pagination, filters, sorter) => {
      setSortedInfo(sorter);
    },
    [setSortedInfo]
  );
  const handleSearch = useCallback((selectedKeys, confirm) => {
    confirm();
  }, []);

  const handleReset = useCallback((clearFilters) => {
    clearFilters();
  }, []);

  useEffect(() => {}, []);

  const checkBankStatementData = (data, file) => {
    const day =
      file.name.split('_').length > 1 &&
      file.name.split('_')[2].split('.').length > 0 &&
      file.name
        .split('_')[2]
        .split('.')[0]
        .substr(6, 2);
    const week =
      day === '07'
        ? 1
        : day === '14'
        ? 2
        : day === '21'
        ? 3
        : day === '29' || day === '30' || day === '31'
        ? 5
        : 4;
    const workdate = dayjs(
      file.name.split('_').length > 1 &&
        file.name.split('_')[2].split('.').length > 0 &&
        file.name.split('_')[2].split('.')[0],
      'YYYYMMDD'
    )
      .format('DDMMYY')
      .toString();

    setBankStatements(parseBankStatementCSV(data, week, workdate));
  };

  const columns = [
    { title: 'Date', dataIndex: 'date', key: 'date' },
    {
      title: 'Type',
      dataIndex: 'description1',
      key: 'description1',
      ...getColumnSortProps({
        index: 'description1',
        type: 'text',
        sortedInfo,
      }),
      ...getColumnSearchProps({
        index: 'description1',
        handleSearch,
        handleReset,
      }),
    },
    {
      title: 'Description',
      dataIndex: 'description2',
      key: 'description2',
      ...getColumnSortProps({
        index: 'description2',
        type: 'text',
        sortedInfo,
      }),
      ...getColumnSearchProps({
        index: 'description2',
        handleSearch,
        handleReset,
      }),
    },
    {
      title: 'Debit',
      dataIndex: 'debit',
      key: 'debit',
      ...getColumnSortProps({
        index: 'debit',
        type: 'number',
        sortedInfo,
      }),
    },
    {
      title: 'Credit',
      dataIndex: 'credit',
      key: 'credit',
      ...getColumnSortProps({
        index: 'credit',
        type: 'number',
        sortedInfo,
      }),
    },
    {
      title: 'Balance',
      dataIndex: 'balance',
      key: 'balance',
      ...getColumnSortProps({
        index: 'balance',
        type: 'number',
        sortedInfo,
      }),
    },
  ];

  return (
    <View>
      <Container>
        <Box flex>
          <Title>Bank Statement</Title>

          {bankStatements && bankStatements.length > 0 ? (
            <>
              <BankStatementResult cols={6} />
              <br />
              <br />
              <Table
                columns={columns}
                dataSource={bankStatements}
                onChange={handleChange}
              />
              <Box my={50}>
                <Button
                  style={{ width: '100%' }}
                  danger
                  onClick={async () => {
                    await clearBankStatements();
                  }}
                >
                  Clear Bank Statement Data
                </Button>
                <br />
                <br />
                <br />
              </Box>
            </>
          ) : (
            <>
              <Box fontSize={10} textAlign="center">
                CLICK OR DRAG & DROP FILE
              </Box>
              <CSVReader
                onDrop={(data, file) => checkBankStatementData(data, file)}
              >
                Upload Bank Statement CSV
              </CSVReader>
            </>
          )}
        </Box>
      </Container>
    </View>
  );
};

const mapStateToProps = ({ bankStatements }) => ({
  bankStatements,
});

export default connect(mapStateToProps, {
  setBankStatements,
  clearBankStatements,
})(BankStatement);
