import React, { useEffect } from 'react';
import { Box, Container, View, Title } from '@common/components';
import {
  setExceptions,
  clearExceptions,
  deleteException,
  fetchExceptions,
} from '@resources/Exception/Actions';
import { createInput, deleteInput } from '@resources/Input/Actions';
import { connect } from 'react-redux';
import { Button, Col, Modal, Row, Table } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

const Exception = ({
  history,
  exceptions,
  clearExceptions,
  deleteException,
  fetchExceptions,
}) => {
  const fetchData = async () => await fetchExceptions();

  useEffect(() => {
    clearExceptions();
    fetchData();
  }, []);

  const columns = [
    { title: 'ID', dataIndex: 'id', key: 'id' },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      onFilter: (value, record) => record.name.indexOf(value) === 0,
      sorter: (a, b) => {
        return a.name.localeCompare(b.name);
      },
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      onFilter: (value, record) => record.name.indexOf(value) === 0,
      sorter: (a, b) => {
        return a.name.localeCompare(b.name);
      },
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (record) => {
        return (
          <>
            <Button
              size="small"
              onClick={() => {
                history.push(`/exceptions/update/${record.id}`);
              }}
            >
              Update
            </Button>
            &nbsp;&nbsp;
            <Button
              danger
              size="small"
              onClick={() => {
                Modal.confirm({
                  title: 'Confirm Detetion',
                  icon: <ExclamationCircleOutlined />,
                  content: `Are you sure you want to delete exception ${record.name}?`,
                  okText: 'Yes',
                  cancelText: 'No',
                  onOk: async () => {
                    await deleteException(record);
                  },
                });
              }}
            >
              Delete
            </Button>
          </>
        );
      },
    },
  ];

  return (
    <View>
      <Container>
        <Box flex>
          <Row align="center" justify="center">
            <Col flex={1}>
              <Title>Exception Cases</Title>
            </Col>
            <Col>
              <Button
                onClick={() => {
                  history.push('/exceptions/create');
                }}
              >
                New Exception Case
              </Button>
            </Col>
          </Row>

          <Table columns={columns} dataSource={exceptions} />
        </Box>
      </Container>
    </View>
  );
};

const mapStateToProps = ({ exceptions, inputs }) => ({
  exceptions,
  inputs,
});

export default connect(mapStateToProps, {
  setExceptions,
  deleteInput,
  createInput,
  clearExceptions,
  deleteException,
  fetchExceptions,
})(Exception);
