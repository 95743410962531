import React, { useCallback, useEffect, useState } from 'react';
import { Box, Container, View, Title } from '@common/components';
import {
  setTithelies,
  clearTithelies,
  parseTitheliesCSV,
} from '@resources/Tithely/Actions';
import { connect } from 'react-redux';
import { CSVReader } from 'react-papaparse';
import { Button, Table } from 'antd';
import TithelyResult from './Fragments/Result';
import { getColumnSearchProps, getColumnSortProps } from '@common/utils/table';
import dayjs from 'dayjs';

const Tithely = ({ tithelies, setTithelies, clearTithelies }) => {
  const [sortedInfo, setSortedInfo] = useState({});
  const handleChange = useCallback(
    (pagination, filters, sorter) => {
      setSortedInfo(sorter);
    },
    [setSortedInfo]
  );
  const handleSearch = useCallback((selectedKeys, confirm) => {
    confirm();
  }, []);

  const handleReset = useCallback((clearFilters) => {
    clearFilters();
  }, []);

  useEffect(() => {}, []);

  const checkTithelyData = (data, file) => {
    const workdate = dayjs(
      file.name.replace('Tithely ', '').replace('.csv', ''),
      'YYYYMMDD'
    )
      .format('DDMMYY')
      .toString();
    setTithelies(parseTitheliesCSV(data, workdate));
  };

  const columns = [
    { title: 'ID', dataIndex: 'id', key: 'id' },
    { title: 'Date', dataIndex: 'date', key: 'date' },
    {
      title: 'Last Name',
      dataIndex: 'lastName',
      key: 'lastName',
      ...getColumnSortProps({
        index: 'lastName',
        type: 'text',
        sortedInfo,
      }),
      ...getColumnSearchProps({
        index: 'lastName',
        handleSearch,
        handleReset,
      }),
    },
    {
      title: 'First Name',
      dataIndex: 'firstName',
      key: 'firstName',
      ...getColumnSortProps({
        index: 'firstName',
        type: 'text',
        sortedInfo,
      }),
      ...getColumnSearchProps({
        index: 'firstName',
        handleSearch,
        handleReset,
      }),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      ...getColumnSortProps({
        index: 'email',
        type: 'text',
        sortedInfo,
      }),
      ...getColumnSearchProps({
        index: 'email',
        handleSearch,
        handleReset,
      }),
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      ...getColumnSortProps({
        index: 'type',
        type: 'text',
        sortedInfo,
      }),
      ...getColumnSearchProps({
        index: 'type',
        handleSearch,
        handleReset,
      }),
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      ...getColumnSortProps({
        index: 'amount',
        type: 'number',
        sortedInfo,
      }),
    },
  ];

  return (
    <View>
      <Container>
        <Box flex>
          <Title>Tithely</Title>

          {tithelies && tithelies.length > 0 ? (
            <>
              <TithelyResult cols={6} />
              <Table
                columns={columns}
                dataSource={tithelies}
                onChange={handleChange}
              />
              <Box my={50}>
                <Button
                  style={{ width: '100%' }}
                  danger
                  onClick={async () => {
                    await clearTithelies();
                  }}
                >
                  Clear Tithely Data
                </Button>
                <br />
                <br />
                <br />
              </Box>
            </>
          ) : (
            <>
              <Box fontSize={10} textAlign="center">
                CLICK OR DRAG & DROP FILE
              </Box>
              <CSVReader onDrop={(data, file) => checkTithelyData(data, file)}>
                Upload Tithely CSV
              </CSVReader>
            </>
          )}
        </Box>
      </Container>
    </View>
  );
};

const mapStateToProps = ({ tithelies }) => ({
  tithelies,
});

export default connect(mapStateToProps, { setTithelies, clearTithelies })(
  Tithely
);
