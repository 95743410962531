import {
  SET_INPUTS,
  SET_INPUT,
  CLEAR_INPUTS,
  CLEAR_INPUT,
  ADD_INPUT,
  UPDATE_INPUT,
  REMOVE_INPUT,
} from './ActionTypes';

export const input = (state = {}, input) => {
  switch (input.type) {
    case SET_INPUT:
      return input.payload;
    case UPDATE_INPUT:
      if (state._id.toString() !== input.payload.id.toString()) {
        return state;
      }

      // Otherwise, this is the one we want - return an updated value
      return {
        ...state,
        ...input.payload,
      };
    case CLEAR_INPUT:
      return {};
    default:
      return state;
  }
};

export default (state = [], input) => {
  switch (input.type) {
    case SET_INPUTS:
      return input.payload;
    case ADD_INPUT:
      return [...state, input.payload];
    case UPDATE_INPUT:
      return state.map((item) => {
        if (item.id !== input.payload.id) {
          return item;
        }

        // Otherwise, this is the one we want - return an updated value
        return {
          ...item,
          ...input.payload,
        };
      });
    case REMOVE_INPUT:
      return state.filter((item) => {
        return item.id !== input.payload.id;
      });
    case CLEAR_INPUTS:
      return [];
    default:
      return state;
  }
};
