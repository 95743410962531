import {
  SET_BANK_STATEMENTS,
  SET_BANK_STATEMENT,
  ADD_BANK_STATEMENT,
  ADD_BANK_STATEMENTS,
  CLEAR_BANK_STATEMENTS,
} from './ActionTypes';

const setBankStatements = (members) => (dispatch) => {
  dispatch({
    type: SET_BANK_STATEMENTS,
    payload: members,
  });
};

const setBankStatement = (member) => (dispatch) => {
  dispatch({
    type: SET_BANK_STATEMENT,
    payload: member,
  });
};

const addBankStatement = (member) => (dispatch) => {
  dispatch({
    type: ADD_BANK_STATEMENT,
    payload: member,
  });
};

const addBankStatements = (member) => (dispatch) => {
  dispatch({
    type: ADD_BANK_STATEMENTS,
    payload: member,
  });
};

const clearBankStatements = () => (dispatch) => {
  dispatch({
    type: CLEAR_BANK_STATEMENTS,
  });
};

const parseBankStatementCSV = (bankStatementCSV, week, workdate) => {
  const data = [];

  bankStatementCSV &&
    bankStatementCSV.length > 0 &&
    bankStatementCSV.forEach((item, index) => {
      if (index > 5 && index < bankStatementCSV.length - 2) {
        data.push({
          workdate,
          week,
          date: item.data[0],
          description1: item.data[2],
          description2: item.data[3]
            ? item.data[3].replace(/\s+/g, ' ')
            : item.data[3],
          description3: item.data[3],
          debit: item.data[4] ? item.data[4].replace(/,/g, '') : 0,
          credit: item.data[5] ? item.data[5].replace(/,/g, '') : 0,
          balance: item.data[6] ? item.data[6].replace(/,/g, '') : 0,
        });
      }
    });

  return data;
};

export {
  setBankStatements,
  setBankStatement,
  addBankStatement,
  addBankStatements,
  clearBankStatements,
  parseBankStatementCSV,
};
