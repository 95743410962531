import React, { useEffect } from 'react';
import { Affix, Button, PageHeader } from 'antd';
import logo from './assets/logo.png';
import { connect } from 'react-redux';
import { fetchCategories } from '@resources/Category/Actions';
import { fetchInputs } from '@resources/Input/Actions';
import { fetchExceptions } from '@resources/Exception/Actions';
import { CheckCircleTwoTone } from '@ant-design/icons';

const Navbar = ({
  history,
  match,
  members,
  user,
  bankStatements,
  tithelies,
  fetchCategories,
  fetchInputs,
  fetchExceptions,
}) => {
  const fetchData = async () => {
    await fetchCategories();
    await fetchInputs();
    await fetchExceptions();
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Affix style={{ zIndex: 100, width: '100%' }}>
        <PageHeader
          className="site-page-header"
          backIcon={false}
          title={
            <span>
              <img src={logo} width={100} alt="Logo" />
            </span>
          }
          subTitle="Extraction Tool"
          extra={
            user && user.id
              ? [
                  <Button
                    key="1"
                    onClick={() => {
                      history.push('/');
                    }}
                    type={match.url === '/' ? 'primary' : 'text'}
                  >
                    Dashboard
                  </Button>,
                  <Button
                    key="2"
                    onClick={() => {
                      history.push('/categories');
                    }}
                    type={match.url === '/categories' ? 'primary' : 'text'}
                  >
                    Categories
                  </Button>,
                  <Button
                    key="21"
                    onClick={() => {
                      history.push('/exceptions');
                    }}
                    type={match.url === '/exceptions' ? 'primary' : 'text'}
                  >
                    Exceptions
                  </Button>,

                  <Button
                    key="3"
                    onClick={() => {
                      history.push('/members');
                    }}
                    type={match.url === '/members' ? 'primary' : 'text'}
                  >
                    Members{' '}
                    {members && members.length > 0 && (
                      <CheckCircleTwoTone twoToneColor="#52c41a" size={30} />
                    )}
                  </Button>,

                  <Button
                    key="4"
                    onClick={() => {
                      history.push('/bank-statements');
                    }}
                    type={match.url === '/bank-statements' ? 'primary' : 'text'}
                  >
                    Bank Statement{' '}
                    {bankStatements && bankStatements.length > 0 && (
                      <CheckCircleTwoTone twoToneColor="#52c41a" size={30} />
                    )}
                  </Button>,
                  <Button
                    key="5"
                    onClick={() => {
                      history.push('/tithelies');
                    }}
                    type={match.url === '/tithelies' ? 'primary' : 'text'}
                  >
                    Tithely{' '}
                    {tithelies && tithelies.length > 0 && (
                      <CheckCircleTwoTone twoToneColor="#52c41a" size={30} />
                    )}
                  </Button>,
                  <Button
                    key="6"
                    onClick={() => {
                      history.push('/extraction');
                    }}
                    type={match.url === '/extraction' ? 'primary' : 'text'}
                  >
                    Extractor
                  </Button>,
                ]
              : null
          }
        />
      </Affix>
    </>
  );
};

const mapStateToProps = ({ bankStatements, members, tithelies, user }) => ({
  bankStatements,
  members,
  tithelies,
  user,
});

export default connect(mapStateToProps, {
  fetchCategories,
  fetchInputs,
  fetchExceptions,
})(Navbar);
