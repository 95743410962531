import React, { useEffect, useState } from 'react';
import { Box, Container, View, Title } from '@common/components';
import {
  setCategories,
  clearCategories,
  deleteCategory,
  fetchCategories,
} from '@resources/Category/Actions';
import { createInput, deleteInput } from '@resources/Input/Actions';
import { connect } from 'react-redux';
import {
  Button,
  Col,
  Form,
  Input,
  message,
  Modal,
  Row,
  Table,
  Tag,
} from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

const Category = ({
  history,
  categories,
  inputs,
  createInput,
  clearCategories,
  deleteInput,
  deleteCategory,
  fetchCategories,
}) => {
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const [cat, setCat] = useState({});
  const [loading, setLoading] = useState(false);
  const fetchData = async () => await fetchCategories();

  useEffect(() => {
    clearCategories();
    fetchData();
  }, []);

  const handleSubmit = async (values) => {
    setLoading(true);
    await createInput({ ...values, category: cat.id });
    setLoading(false);
    setVisible(false);
    form.resetFields();
    message.success(
      `The input ${values.title} has been successfully added to category ${cat.title}`
    );
  };

  const columns = [
    { title: 'ID', dataIndex: 'id', key: 'id' },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      onFilter: (value, record) => record.title.indexOf(value) === 0,
      sorter: (a, b) => {
        return a.title.localeCompare(b.title);
      },
      sortDirections: ['ascend', 'descend'],
    },
    { title: 'Description', dataIndex: 'description', key: 'description' },
    {
      title: 'Inputs',
      key: 'inputs',
      render: (record, item) => {
        return (
          <>
            {inputs
              .filter((inp) => {
                return inp.category === record.id;
              })
              .map((inp) => (
                <Tag
                  closable
                  onClose={(e) => {
                    Modal.confirm({
                      title: 'Confirm Detetion',
                      icon: <ExclamationCircleOutlined />,
                      content: `Are you sure you want to delete input ${inp.title} for category ${record.title}?`,
                      okText: 'Yes',
                      cancelText: 'No',
                      onOk: async () => {
                        await deleteInput(inp);
                      },
                    });
                    e.preventDefault();
                  }}
                >
                  {inp.title}
                </Tag>
              ))}
            &nbsp;&nbsp;
            <Button
              size="small"
              onClick={() => {
                form.resetFields();
                setCat(record);
                setVisible(true);
              }}
            >
              + Add Input
            </Button>
          </>
        );
      },
    },

    {
      title: 'Actions',
      key: 'actions',
      render: (record) => {
        return (
          <>
            <Button
              size="small"
              onClick={() => {
                history.push(`/categories/update/${record.id}`);
              }}
            >
              Update
            </Button>
            &nbsp;&nbsp;
            <Button
              danger
              size="small"
              onClick={() => {
                Modal.confirm({
                  title: 'Confirm Detetion',
                  icon: <ExclamationCircleOutlined />,
                  content: `Are you sure you want to delete category ${record.title}?`,
                  okText: 'Yes',
                  cancelText: 'No',
                  onOk: async () => {
                    await deleteCategory(record);
                  },
                });
              }}
            >
              Delete
            </Button>
          </>
        );
      },
    },
  ];

  return (
    <View>
      <Container>
        <Box flex>
          <Row align="center" justify="center">
            <Col flex={1}>
              <Title>Categories</Title>
            </Col>
            <Col>
              <Button
                onClick={() => {
                  history.push('/categories/create');
                }}
              >
                New Category
              </Button>
            </Col>
          </Row>

          <Table columns={columns} dataSource={categories} />
        </Box>
      </Container>

      <Modal
        title="Add Input"
        visible={visible}
        onCancel={() => setVisible(false)}
        footer={null}
      >
        {cat.id}
        <Title>{cat.title}</Title>

        <br />
        <Form form={form} onFinish={handleSubmit}>
          <Form.Item
            label="Input"
            name="title"
            rules={[
              {
                required: true,
                message: `Please enter category input`,
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item>
            <Button type="secondary" onClick={() => setVisible(false)}>
              Cancel
            </Button>
            &nbsp;&nbsp;
            <Button
              loading={loading}
              type="primary"
              htmlType="submit"
              className="login-form-button"
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </View>
  );
};

const mapStateToProps = ({ categories, inputs }) => ({
  categories,
  inputs,
});

export default connect(mapStateToProps, {
  setCategories,
  deleteInput,
  createInput,
  clearCategories,
  deleteCategory,
  fetchCategories,
})(Category);
