import React from 'react';
import { Button, Input } from 'antd';
import { toNumber } from 'lodash';
import moment from 'moment';
import { SearchOutlined } from '@ant-design/icons';

let searchInput;
export const getColumnSearchProps = ({ index, handleSearch, handleReset }) => ({
  filterDropdown: ({
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
  }) => (
    <div style={{ padding: 8 }}>
      <Input
        ref={(node) => {
          searchInput = node;
        }}
        placeholder={`Search ${index}`}
        value={selectedKeys[0]}
        onChange={(e) =>
          setSelectedKeys(e.target.value ? [e.target.value] : [])
        }
        onPressEnter={() => handleSearch(selectedKeys, confirm)}
        style={{ width: 188, marginBottom: 8, display: 'block' }}
      />
      <Button
        type="primary"
        onClick={() => handleSearch(selectedKeys, confirm)}
        icon={<SearchOutlined />}
        size="small"
        style={{ width: 90, marginRight: 8 }}
      >
        Search
      </Button>
      <Button
        onClick={() => handleReset(clearFilters)}
        size="small"
        style={{ width: 90 }}
      >
        Reset
      </Button>
    </div>
  ),
  filterIcon: (filtered) => (
    <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
  ),
  onFilter: (value, record) => {
    let di = index;
    if (di.indexOf('.') > -1) {
      di = di.split('.');
      if (di.length === 3) {
        return record[di[0]][di[1]][di[2]]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase());
      } else {
        return record[di[0]][di[1]]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase());
      }
    } else {
      if (typeof record[index] !== 'boolean') {
        return record[index]
          ? record[index]
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase())
          : false;
      } else {
        return record[index] === value;
      }
    }
  },
  onFilterDropdownVisibleChange: (visible) => {
    if (visible) {
      setTimeout(() => searchInput.select());
    }
  },
});

export const getColumnSortProps = ({ index, type, sortedInfo }) => {
  switch (type) {
    case 'text':
      return {
        sorter: (a, b) => {
          if (a[index] && b[index]) {
            if (a[index].toUpperCase() < b[index].toUpperCase()) {
              return -1;
            }

            if (a[index].toUpperCase() > b[index].toUpperCase()) {
              return 1;
            }
          }

          return 0;
        },
        sortOrder: sortedInfo.columnKey === index && sortedInfo.order,
      };
    case 'number':
      return {
        sorter: (a, b) => {
          if (a[index] && b[index]) {
            if (toNumber(a[index]) < toNumber(b[index])) {
              return -1;
            }

            if (toNumber(a[index]) > toNumber(b[index])) {
              return 1;
            }
          }

          return 0;
        },
        sortOrder: sortedInfo.columnKey === index && sortedInfo.order,
      };
    case 'date':
      return {
        sorter: (a, b) => {
          if (
            toNumber(moment(a[index]).format('YYYYMMDDHHmmss')) <
            toNumber(moment(b[index]).format('YYYYMMDDHHmmss'))
          ) {
            return -1;
          }

          if (
            toNumber(moment(a[index]).format('YYYYMMDDHHmmss')) >
            toNumber(moment(b[index]).format('YYYYMMDDHHmmss'))
          ) {
            return 1;
          }

          return 0;
        },
        sortOrder: sortedInfo.columnKey === index && sortedInfo.order,
      };
    default:
      break;
  }
};

export const getColumnFilterProps = ({
  index,
  filters = [],
  multiple = true,
  isArray,
}) => {
  return {
    filters,
    multiple,
    onFilter: (value, record) =>
      isArray && typeof record[index] !== 'boolean'
        ? record[index] &&
          record[index].filter((data) => data._id === value).length === 1
        : typeof record[index] === 'boolean'
        ? record[index] === value
        : record[index] && record[index].indexOf(value) > -1,
  };
};
