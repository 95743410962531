import React, { useState, useCallback, useEffect } from "react";
import { Box, Container, View, Title } from "@common/components";
import { connect } from "react-redux";
import { Button, Table, Tabs } from "antd";
import ReactExport from "react-data-export";
import dayjs from "dayjs";
import { getColumnSearchProps, getColumnSortProps } from "@common/utils/table";

const { TabPane } = Tabs;

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
const months = [
	"January",
	"February",
	"March",
	"April",
	"May",
	"June",
	"July",
	"August",
	"September",
	"October",
	"November",
	"December"
];

const monthsAll = [
	{ text: "January", index: 0 },
	{ text: "Jan", index: 0 },
	{ text: "February", index: 1 },
	{ text: "Feb", index: 1 },
	{ text: "March", index: 2 },
	{ text: "Mar", index: 2 },
	{ text: "April", index: 3 },
	{ text: "Apr", index: 3 },
	{ text: "May", index: 4 },
	{ text: "Jun", index: 5 },
	{ text: "June", index: 5 },
	{ text: "Jul", index: 6 },
	{ text: "July", index: 6 },
	{ text: "August", index: 7 },
	{ text: "Aug", index: 7 },
	{ text: "September", index: 8 },
	{ text: "Sep", index: 8 },
	{ text: "Sept", index: 8 },
	{ text: "October", index: 9 },
	{ text: "Oct", index: 9 },
	{ text: "November", index: 10 },
	{ text: "Nov", index: 10 },
	{ text: "December", index: 11 },
	{ text: "Dec", index: 11 }
];

const monthsCombo = [];

monthsAll.forEach((mon) => {
	monthsAll.forEach((mont) => {
		if (mon.index - mont.index !== 0) {
			const diff = mont.index - mon.index;
			const mos = [];
			if (diff > 0) {
				for (let i = mon.index; i <= mont.index; i++) {
					mos.push(months[i]);
				}
			}

			if (diff < 0) {
				for (let i = mon.index; i <= 11; i++) {
					mos.push(months[i]);
				}

				for (let j = 0; j <= mont.index; j++) {
					mos.push(months[j]);
				}
			}

			monthsCombo.push({ text: ` ${mon.text}-${mont.text} `, months: mos });
			monthsCombo.push({ text: ` ${mon.text} to ${mont.text}`, months: mos });
		}
	});
});

const curYear = dayjs();
const lastYear = dayjs().subtract(1, "year");
const nextYear = dayjs().add(1, "year");

const yearCheck = (d, month, year) => {
	return (
		d.toLowerCase().indexOf(
			` tithing${month.toLowerCase()}${year.format("YYYY").toString()} ` // ' tithingjanuary2024
		) > -1 ||
		d.toLowerCase().indexOf(
			`tithing${month.toLowerCase()}${year.format("YYYY").toString()} ` // 'tithingjanuary2024
		) > -1 ||
		d.toLowerCase().indexOf(
			` tithe${month.toLowerCase()}${year.format("YYYY").toString()} ` // ' tithejanuary2024'
		) > -1 ||
		d.toLowerCase().indexOf(
			`tithe${month.toLowerCase()}${year.format("YYYY").toString()} ` // 'tithejanuary2024'
		) > -1 ||
		d
			.toLowerCase()
			.indexOf(` ${month.toLowerCase()}${year.format("YYYY").toString()}-`) > // ' january2024-'
			-1 ||
		d
			.toLowerCase()
			.indexOf(` ${month.toLowerCase()}${year.format("YYYY").toString()} `) > // ' january2024 '
			-1 ||
		d
			.toLowerCase()
			.indexOf(`-${month.toLowerCase()}${year.format("YYYY").toString()} `) > // '-january2024 '
			-1 ||
		d.toLowerCase().indexOf(
			` tithing${month.substr(0, 4).toLowerCase()}${year
				.format("YYYY")
				.toString()} ` // ' tithingjanu2024 '
		) > -1 ||
		d.toLowerCase().indexOf(
			`tithing${month.substr(0, 4).toLowerCase()}${year
				.format("YYYY")
				.toString()} ` // 'tithingjanu2024 '
		) > -1 ||
		d.toLowerCase().indexOf(
			` tithe${month.substr(0, 4).toLowerCase()}${year
				.format("YYYY")
				.toString()} ` // ' tithejanu2024 '
		) > -1 ||
		d.toLowerCase().indexOf(
			`tithe${month.substr(0, 4).toLowerCase()}${year
				.format("YYYY")
				.toString()} ` // 'tithejanu2024 '
		) > -1 ||
		d.toLowerCase().indexOf(
			` ${month.substr(0, 4).toLowerCase()}${year.format("YYYY").toString()} ` // ' janu2024 '
		) > -1 ||
		d.toLowerCase().indexOf(
			` ${month.substr(0, 4).toLowerCase()}${year.format("YYYY").toString()}-` // ' janu2024-'
		) > -1 ||
		d.toLowerCase().indexOf(
			`-${month.substr(0, 4).toLowerCase()}${year.format("YYYY").toString()} ` // '-janu2024 '
		) > -1 ||
		d.toLowerCase().indexOf(
			` tithing${month.substr(0, 4).toLowerCase()}${year
				.format("YY")
				.toString()} ` // ' tithingjanu24'
		) > -1 ||
		d.toLowerCase().indexOf(
			`tithing${month.substr(0, 4).toLowerCase()}${year
				.format("YY")
				.toString()} ` // 'tithingjanu24 '
		) > -1 ||
		d.toLowerCase().indexOf(
			` tithe${month.substr(0, 4).toLowerCase()}${year
				.format("YY")
				.toString()} ` // ' tithejanu24 '
		) > -1 ||
		d.toLowerCase().indexOf(
			`tithe${month.substr(0, 4).toLowerCase()}${year.format("YY").toString()} ` // 'tithejanu24 '
		) > -1 ||
		d.toLowerCase().indexOf(
			` ${month.substr(0, 4).toLowerCase()}${year.format("YY").toString()} ` // ' janu24 '
		) > -1 ||
		d.toLowerCase().indexOf(
			` ${month.substr(0, 4).toLowerCase()}${year.format("YY").toString()}-` // ' janu24-'
		) > -1 ||
		d.toLowerCase().indexOf(
			`-${month.substr(0, 4).toLowerCase()}${year.format("YY").toString()} ` // '-janu24 '
		) > -1 ||
		d.toLowerCase().indexOf(
			` tithing${month.substr(0, 3).toLowerCase()}${year
				.format("YY")
				.toString()} ` // ' tithingjan24 '
		) > -1 ||
		d.toLowerCase().indexOf(
			`tithing${month.substr(0, 3).toLowerCase()}${year
				.format("YY")
				.toString()} ` // 'tithingjan24'
		) > -1 ||
		d.toLowerCase().indexOf(
			` tithe${month.substr(0, 3).toLowerCase()}${year
				.format("YY")
				.toString()} ` // ' tithejan24 '
		) > -1 ||
		d.toLowerCase().indexOf(
			`tithe${month.substr(0, 3).toLowerCase()}${year.format("YY").toString()} ` // 'tithejan24'
		) > -1 ||
		d.toLowerCase().indexOf(
			` ${month.substr(0, 3).toLowerCase()}${year.format("YY").toString()} ` // ' jan24 '
		) > -1 ||
		d.toLowerCase().indexOf(
			` ${month.substr(0, 3).toLowerCase()}${year.format("YY").toString()}-` // ' jan24-'
		) > -1 ||
		d.toLowerCase().indexOf(
			`-${month.substr(0, 3).toLowerCase()}${year.format("YY").toString()} ` // '-jan24'
		) > -1 ||
		d.toLowerCase().indexOf(
			` ${month.substr(0, 3).toLowerCase()} ${year.format("YY").toString()} ` // ' jan 24 '
		) > -1 ||
		d.toLowerCase().indexOf(
			` ${month.substr(0, 3).toLowerCase()} ${year.format("YYYY").toString()} ` // ' jan 2024 '
		) > -1 ||
		d.toLowerCase().indexOf(
			` ${month.toLowerCase()} ${year.format("YY").toString()} ` // ' january 24 '
		) > -1 ||
		d.toLowerCase().indexOf(
			` ${month.toLowerCase()} ${year.format("YYYY").toString()} ` // ' january 2024 '
		) > -1 ||
		d.toLowerCase().indexOf(
			`${month.substr(0, 3).toLowerCase()}${year.format("YY").toString()} ` // 'jan24'
		) > -1 ||
		d.toLowerCase().indexOf(
			`${month.substr(0, 3).toLowerCase()}${year.format("YYYY").toString()}` // 'jan2024'
		) > -1 ||
		d.toLowerCase().indexOf(
			`${month.substr(0, 3).toLowerCase()} ${year.format("YYYY").toString()}` // 'jan 2024'
		) > -1 ||
		d.toLowerCase().indexOf(
			`${month.substr(0, 3).toLowerCase()}-${year.format("YY").toString()}` // 'jan-24'
		) > -1 ||
		d.toLowerCase().indexOf(
			`${month.substr(0, 3).toLowerCase()}-${year.format("YYYY").toString()}` // 'jan-2024'
		) > -1 ||
		d.toLowerCase().indexOf(
			`${month.toLowerCase()} ${year.format("YY").toString()}` // 'january 24'
		) > -1 ||
		d.toLowerCase().indexOf(
			`${month.toLowerCase()} ${year.format("YYYY").toString()}` // 'january 2024'
		) > -1
	);
};

const Extraction = ({
	categories,
	exceptions,
	inputs,
	members,
	bankStatements,
	tithelies
}) => {
	const [loading, setLoading] = useState(false);
	const [bankStatementData, setBankStatementData] = useState([]);
	const [tithelyData, setTithelyData] = useState([]);
	const [cats] = useState([...categories]);
	const [exs] = useState([...exceptions]);
	const [mems] = useState([...members]);
	const [inps] = useState(
		[...inputs].filter((input) => {
			return (
				cats
					.sort((a, b) => b.title.length - a.title.length)
					.find((cat) => cat.id === input.category) !== undefined
			);
		})
	);

	useEffect(() => {
		return () => {
			setBankStatementData([]);
			setTithelyData([]);
		};
	}, []);

	const [sortedInfo, setSortedInfo] = useState({});
	const handleChange = useCallback(
		(pagination, filters, sorter) => {
			setSortedInfo(sorter);
		},
		[setSortedInfo]
	);

	const handleSearch = useCallback((selectedKeys, confirm) => {
		confirm();
	}, []);

	const handleReset = useCallback((clearFilters) => {
		clearFilters();
	}, []);
	const bankStatementColumns = [
		{
			title: "Category",
			dataIndex: "category",
			key: "category",
			fixed: "left",

			width: 180,

			filters: cats
				.sort((a, b) => a.title && b.title && a.title.localeCompare(b.title))
				.map((cat) => ({
					key: cat.id,
					text: cat.title.toUpperCase(),
					value: cat.title.toUpperCase()
				})),
			onFilter: (value, record) =>
				record.category.toUpperCase().indexOf(value) === 0,

			...getColumnSortProps({
				index: "category",
				type: "text",
				sortedInfo
			})
		},
		{
			title: "Entity Key",
			dataIndex: "entity",
			key: "entity",
			fixed: "left",
			width: 180,
			...getColumnSortProps({
				index: "entity",
				type: "text",
				sortedInfo
			}),
			...getColumnSearchProps({
				index: "entity",
				handleSearch,
				handleReset
			})
		},
		{ title: "Date", dataIndex: "date", key: "date" },
		{
			title: "For Period",
			dataIndex: "remarks",
			key: "remarks",
			...getColumnSortProps({
				index: "remarks",
				type: "text",
				sortedInfo
			}),
			...getColumnSearchProps({
				index: "remarks",
				handleSearch,
				handleReset
			})
		},
		{
			title: "Amount",
			dataIndex: "amount",
			key: "amount",
			...getColumnSortProps({
				index: "amount",
				type: "number",
				sortedInfo
			})
		},
		{
			title: "ID",
			dataIndex: "id",
			key: "id",
			...getColumnSortProps({
				index: "remarks",
				type: "text",
				sortedInfo
			}),
			...getColumnSearchProps({
				index: "remarks",
				handleSearch,
				handleReset
			})
		},
		{
			title: "Cong",
			dataIndex: "cong",
			key: "cong",
			...getColumnSortProps({
				index: "cong",
				type: "text",
				sortedInfo
			}),
			...getColumnSearchProps({
				index: "cong",
				handleSearch,
				handleReset
			})
		},
		{
			title: "Region",
			dataIndex: "region",
			key: "region",
			...getColumnSortProps({
				index: "region",
				type: "text",
				sortedInfo
			}),
			...getColumnSearchProps({
				index: "region",
				handleSearch,
				handleReset
			})
		},
		{
			title: "Transaction Description 2",
			dataIndex: "description2",
			key: "description2",
			...getColumnSearchProps({
				index: "description2",
				handleSearch,
				handleReset
			})
		},
		{
			title: "Remarks",
			dataIndex: "blank",
			key: "blank",
			...getColumnSearchProps({
				index: "blank",
				handleSearch,
				handleReset
			})
		},
		{
			title: "Status",
			dataIndex: "status",
			key: "status",
			...getColumnSearchProps({
				index: "status",
				handleSearch,
				handleReset
			})
		}
	];

	const tithelyColumns = [
		{
			title: "Category",
			dataIndex: "category",
			key: "category",
			fixed: "left",

			width: 180,
			filters: cats
				.sort((a, b) => a.title && b.title && a.title.localeCompare(b.title))
				.map((cat) => ({
					key: cat.id,
					text: cat.title.toUpperCase(),
					value: cat.title.toUpperCase()
				})),
			onFilter: (value, record) =>
				record.category.toUpperCase().indexOf(value) === 0,

			...getColumnSortProps({
				index: "category",
				type: "text",
				sortedInfo
			})
		},
		{
			title: "Entity Key",
			dataIndex: "entity",
			key: "entity",
			fixed: "left",
			width: 180,
			...getColumnSortProps({
				index: "entity",
				type: "text",
				sortedInfo
			}),
			...getColumnSearchProps({
				index: "entity",
				handleSearch,
				handleReset
			})
		},
		{
			title: "Date",
			dataIndex: "date",
			key: "date",
			...getColumnSortProps({
				index: "date",
				type: "date",
				sortedInfo
			})
		},
		{
			title: "For Period",
			dataIndex: "remarks",
			key: "remarks",
			...getColumnSearchProps({
				index: "remarks",
				handleSearch,
				handleReset
			})
		},
		{ title: "Amount", dataIndex: "amount", key: "amount" },
		{ title: "ID", dataIndex: "id", key: "id" },
		{
			title: "Cong",
			dataIndex: "cong",
			key: "cong",
			...getColumnSearchProps({
				index: "cong",
				handleSearch,
				handleReset
			})
		},
		{
			title: "Region",
			dataIndex: "region",
			key: "region",
			...getColumnSearchProps({
				index: "region",
				handleSearch,
				handleReset
			})
		},
		{
			title: "Giving Type",
			dataIndex: "type",
			key: "type"
		},
		// {
		// 	title: "Check / iBank / NETS Number",
		// 	dataIndex: "checkIbankNets",
		// 	key: "checkIbankNets"
		// },
		{
			title: "Memo / Note",
			dataIndex: "memo",
			key: "memo"
		},
		{
			title: "Backdate",
			dataIndex: "blank",
			key: "blank"
		},
		{
			title: "Remarks",
			dataIndex: "blank",
			key: "blank"
		},
		{
			title: "Status",
			dataIndex: "status",
			key: "status"
		}
	];

	const summaryColumn = [
		{
			title: "Category",
			dataIndex: "category",
			key: "category",
			fixed: "left",

			width: 180,
			filters: cats
				.sort((a, b) => a.title && b.title && a.title.localeCompare(b.title))
				.map((cat) => ({
					key: cat.id,
					text: cat.title.toUpperCase(),
					value: cat.title.toUpperCase()
				})),
			onFilter: (value, record) =>
				record.category.toUpperCase().indexOf(value) === 0,

			...getColumnSortProps({
				index: "category",
				type: "text",
				sortedInfo
			})
		},
		{
			title: "Cong",
			dataIndex: "cong",
			key: "cong",
			...getColumnSearchProps({
				index: "cong",
				handleSearch,
				handleReset
			})
		},
		{
			title: "Region",
			dataIndex: "region",
			key: "region",
			...getColumnSearchProps({
				index: "region",
				handleSearch,
				handleReset
			})
		},
		{ title: "Total", dataIndex: "total", key: "total" }
	];

	const memberSearch = (desc, name) => {
		let me = [...mems];

		const descs = desc && desc.toLowerCase();
		let member =
			desc && me
				? me.filter((member) => {
						if (
							member.memberID &&
							descs.indexOf(member.memberID.toLowerCase()) > -1
						) {
							return true;
						}

						return false;
				  })
				: null;

		if (member && member.length > 0) {
			if (member.length > 1 && name) {
				return null;
			}
			return member;
		}

		const exes = [...exs];

		const excepts =
			exes &&
			exes.filter((exception) => {
				if (
					desc &&
					desc.toLowerCase().indexOf(exception.name.toLowerCase()) > -1
				) {
					return true;
				}

				if (
					name &&
					name.toLowerCase().indexOf(exception.name.toLowerCase()) > -1
				) {
					return true;
				}

				return false;
			});

		if (excepts && excepts.length > 0) {
			return excepts[0].action;
		}

		me = [...mems];

		const descss =
			name &&
			name
				.toLowerCase()
				.replace(/,/g, "")
				.replace(/-/g, " ")
				.replace("(", "")
				.replace(")", "");

		const descSplit = descss && descss.split(" ").filter((d) => d !== "");

		member =
			me && name
				? me
						.sort(
							(a, b) =>
								`${b.firstName} ${b.lastName}`.length -
								`${a.firstName} ${a.lastName}`.length
						)
						.filter((member) => {
							if (name) {
								const fname = member.firstName
									? member.firstName
											.toLowerCase()
											.replace(", ", " ")
											.replace("-", " ")
											.replace("(", "")
											.replace(")", "")
											.split(" ")
											.filter((m) => (m.indexOf(".") > -1 ? false : true))
											.join(" ")
									: "";
								const lname = member.lastName
									? member.lastName
											.toLowerCase()
											.replace(", ", " ")
											.replace("-", " ")
											.replace("(", "")
											.replace(")", "")
											.split(" ")
											.filter((m) => (m.indexOf(".") > -1 ? false : true))
											.join(" ")
									: "";

								const fnameLen =
									fname.indexOf(" ") > -1 ? fname.split(" ").length : 1;
								const lnameLen =
									lname.indexOf(" ") > -1 ? lname.split(" ").length : 1;

								const totalLen = fnameLen + lnameLen;

								// if (descss.indexOf('lim chee harn') > -1) {
								//   console.log(
								//     member.firstName,
								//     member.lastName,
								//     fname,
								//     lname,
								//     member.firstName &&
								//       member.lastName &&
								//       descSplit.length > 1 &&
								//       descSplit.filter(
								//         (dec) =>
								//           `${fname} `.indexOf(`${dec} `) > -1 ||
								//           `${lname} `.indexOf(`${dec} `) > -1
								//       ).length,
								//     totalLen,
								//     descSplit.length,
								//     member.firstName &&
								//       member.lastName &&
								//       descSplit.length > 1 &&
								//       descSplit.filter(
								//         (dec) =>
								//           `${fname} `.indexOf(`${dec} `) > -1 ||
								//           `${lname} `.indexOf(`${dec} `) > -1
								//       ).length >=
								//         (descSplit.length > totalLen
								//           ? totalLen
								//           : descSplit.length)
								//   );
								// }

								if (
									member.firstName &&
									member.lastName &&
									descSplit.length > 1 &&
									descSplit.filter(
										(dec) =>
											` ${fname} `.indexOf(` ${dec} `) > -1 ||
											` ${lname} `.indexOf(` ${dec} `) > -1
									).length >=
										(descSplit.length > totalLen ? totalLen : descSplit.length)
								) {
									return true;
								}
							}

							return false;
						})
				: null;

		// if (name === 'ong jun wei timothy joshua') {
		//   console.log(name, member);
		// }

		if (member && member.length > 0) {
			if (member.length > 1 && name) {
				let highest = 0;
				member.forEach((m) => {
					const grade = `${m.firstName} ${m.lastName}`.split(" ").length;

					if (highest < grade) {
						highest = grade;
					}
				});

				const memberGrade = member.filter((m) => {
					const grade = `${m.firstName} ${m.lastName}`.split(" ").length;

					return highest === grade;
				});

				if (memberGrade.length > 1) {
					return null;
				} else {
					return memberGrade;
				}
			}
			return member;
		} else {
			return null;
		}

		// return null;
	};

	const bankStatementTable = () => {
		const data = [];
		const summary = [];

		bankStatements &&
			bankStatements.forEach((bs) => {
				if (bs.credit !== 0) {
					let descriptionName;
					let fullname;

					if (bs.description1.toLowerCase() === "transfer of fund") {
						descriptionName = bs.description2.split("TRF ");

						if (descriptionName.length === 2) {
							descriptionName = descriptionName[1].split(" I-BANK ");
						}

						if (descriptionName.length === 2) {
							descriptionName = descriptionName[0];
						} else {
							descriptionName = null;
						}
					} else if (
						bs.description1 === "STANDING ORDER INSTRUCTION / SERVICE FEE"
					) {
						if (bs.description3.indexOf("SI  PART/REF:SI") > -1) {
							const descSplit = bs.description3.split(" BY      :");
							descriptionName = descSplit[1];
						} else {
							descriptionName = bs.description3.split(" SI  ");

							if (descriptionName.length === 2) {
								// if (descriptionName[0].indexOf(' SI ') > -1) {
								//   descriptionName[0].replaceAll(' SI ', ' 00 ');
								// }
								// const desc = '0' + descriptionName[0];
								// console.log(desc);
								descriptionName = descriptionName[0].split("SI  ");
								// console.log(descriptionName[1]);
								// if (descriptionName[1].indexOf(' 00 ') > -1) {
								//   descriptionName[1].replaceAll(' 00 ', ' SI ');
								// }
							}

							if (descriptionName.length === 2) {
								descriptionName = descriptionName[1];
							} else {
								descriptionName = null;
							}
						}
					} else if (bs.description1.toLowerCase().indexOf("cheque") > -1) {
						descriptionName = "CHEQUE";
					} else if (bs.description1.toLowerCase().indexOf("interest") > -1) {
						descriptionName = "INTEREST";
					} else if (
						bs.description1.toLowerCase().indexOf("cash transaction") > -1
					) {
						descriptionName = "CASH";
					} else {
						if (bs.description2.indexOf("GOVERNMENT PAYMENT") > -1) {
							descriptionName = null;
						} else if (
							bs.description2.indexOf(" Incoming PayNow From: ") > -1
						) {
							descriptionName = bs.description2
								.toLowerCase()
								.split(" incoming paynow from: ")[1];
						} else {
							descriptionName = bs.description2.toLowerCase().split(" other ");

							if (descriptionName.length !== 2) {
								descriptionName = bs.description2
									.toLowerCase()
									.split(" charity payment ");
							}

							if (descriptionName.length !== 2) {
								descriptionName = bs.description2
									.toLowerCase()
									.split(" business expenses ");
							}

							if (descriptionName.length === 2) {
								descriptionName = descriptionName[1]
									.toLowerCase()
									.split(" sgd ");
							}

							if (descriptionName.length === 2) {
								descriptionName = descriptionName[0];
							} else {
								descriptionName = null;
							}
						}
					}

					if (descriptionName) {
						fullname = descriptionName.toLowerCase().replace(/\s+/g, " ");
						if (descriptionName.indexOf(" (") > -1) {
							descriptionName = descriptionName.split(" (")[0];
						}

						if (descriptionName.indexOf("/") > -1) {
							descriptionName = descriptionName.split("/")[0];
						}

						if (descriptionName.indexOf("@") > -1) {
							descriptionName = descriptionName.split("@")[0];
						}

						descriptionName = descriptionName
							.toLowerCase()
							.replace("paylah! ", "")
							.replace(/\s+/g, " ");
					}

					const member = memberSearch(
						fullname
							? bs.description2.toLowerCase().replace(fullname, "")
							: bs.description2,
						descriptionName ? descriptionName : bs.description2
					);

					const d = fullname
						? ` ${bs.description2.toLowerCase().replace(fullname, "")} `
						: ` ${bs.description2} `;

					const inp = inps
						.sort((a, b) => b.title.length - a.title.length)
						.find((input) => {
							return (
								d.toLowerCase().indexOf(`${input.title.toLowerCase()}`) > -1
							);
						});

					const category = inp
						? cats
								.sort((a, b) => b.title.length - a.title.length)
								.find((cat) => cat.id === inp.category)
						: null;

					let rems = [];

					monthsCombo.forEach((month) => {
						if (d.toLowerCase().indexOf(month.text.toLowerCase()) > -1) {
							rems = [...month.months];
						}
					});

					const remarks =
						rems.length > 0
							? rems
							: months
									.filter((month) => {
										return (
											d
												.toLowerCase()
												.indexOf(` tithing${month.toLowerCase()} `) > -1 ||
											d
												.toLowerCase()
												.indexOf(`tithing${month.toLowerCase()} `) > -1 ||
											d.toLowerCase().indexOf(` tithe${month.toLowerCase()} `) >
												-1 ||
											d.toLowerCase().indexOf(`tithe${month.toLowerCase()} `) >
												-1 ||
											d.toLowerCase().indexOf(` ${month.toLowerCase()}-`) >
												-1 ||
											// d.toLowerCase().indexOf(` ${month.toLowerCase()} `) >
											//   -1 ||
											d.toLowerCase().indexOf(`-${month.toLowerCase()} `) >
												-1 ||
											d
												.toLowerCase()
												.indexOf(
													` tithing${month.substr(0, 4).toLowerCase()} `
												) > -1 ||
											d
												.toLowerCase()
												.indexOf(
													`tithing${month.substr(0, 4).toLowerCase()} `
												) > -1 ||
											d
												.toLowerCase()
												.indexOf(` tithe${month.substr(0, 4).toLowerCase()} `) >
												-1 ||
											d
												.toLowerCase()
												.indexOf(`tithe${month.substr(0, 4).toLowerCase()} `) >
												-1 ||
											// d
											//   .toLowerCase()
											//   .indexOf(` ${month.substr(0, 4).toLowerCase()} `) >
											//   -1 ||
											d
												.toLowerCase()
												.indexOf(` ${month.substr(0, 4).toLowerCase()}-`) >
												-1 ||
											d
												.toLowerCase()
												.indexOf(`-${month.substr(0, 4).toLowerCase()} `) >
												-1 ||
											d
												.toLowerCase()
												.indexOf(
													` tithing${month.substr(0, 3).toLowerCase()} `
												) > -1 ||
											d
												.toLowerCase()
												.indexOf(
													`tithing${month.substr(0, 3).toLowerCase()} `
												) > -1 ||
											d
												.toLowerCase()
												.indexOf(` tithe${month.substr(0, 3).toLowerCase()} `) >
												-1 ||
											d
												.toLowerCase()
												.indexOf(`tithe${month.substr(0, 3).toLowerCase()} `) >
												-1 ||
											// d
											//   .toLowerCase()
											//   .indexOf(` ${month.substr(0, 3).toLowerCase()} `) >
											//   -1 ||
											d
												.toLowerCase()
												.indexOf(` ${month.substr(0, 3).toLowerCase()}-`) >
												-1 ||
											d
												.toLowerCase()
												.indexOf(`-${month.substr(0, 3).toLowerCase()} `) >
												-1 ||
											d
												.toLowerCase()
												.indexOf(` tithe ${month.substr(0, 3).toLowerCase()}`) >
												-1 ||
											d
												.toLowerCase()
												.indexOf(`${month.substr(0, 3).toLowerCase()} tit`) >
												-1 ||
											d.toLowerCase().indexOf(`${month.toLowerCase()} tit`) >
												-1 ||
											yearCheck(d, month, curYear) ||
											yearCheck(d, month, nextYear) ||
											yearCheck(d, month, lastYear)
										);
									})
									.map((month) => {
										if (yearCheck(d, month, nextYear)) {
											return {
												month: month,
												year: nextYear.format("YY").toString()
											};
										}

										if (yearCheck(d, month, lastYear)) {
											return {
												month: month,
												year: lastYear.format("YY").toString()
											};
										}

										return {
											month: month,
											year: curYear.format("YY").toString()
										};
									});

					// const scat =
					// 	descriptionName && descriptionName.toLowerCase() === "interest"
					// 		? "INTEREST"
					// 		: descriptionName && descriptionName.toLowerCase() === "iras"
					// 		? "IRAS"
					// 		: descriptionName && descriptionName.toLowerCase() === "cash"
					// 		? "CASH"
					// 		: descriptionName && descriptionName.toLowerCase() === "cheque"
					// 		? "CHEQUE"
					// 		: category
					// 		? category.title.toUpperCase()
					// 		: "TITHE";

					const scat =
						descriptionName && descriptionName.toLowerCase() === "interest"
							? "Interest"
							: descriptionName && descriptionName.toLowerCase() === "iras"
							? "IRAS"
							: descriptionName && descriptionName.toLowerCase() === "cash"
							? "Cash"
							: descriptionName && descriptionName.toLowerCase() === "cheque"
							? "Cheque"
							: category
							? category.title
							: "Tithe";

					member && member.length > 0 && Array.isArray(member)
						? member.forEach((mem) => {
								const scong = mem
									? mem.congregation
									: descriptionName === "cheque"
									? "BLANK"
									: "Unidentified";
								const sregion = mem
									? mem.region
									: descriptionName === "cheque"
									? "BLANK"
									: "Others";

								remarks && remarks.length > 0
									? remarks.forEach((remark, index) => {
											let leftover = 0;
											if (index === 0) {
												leftover =
													bs.credit -
													parseFloat(
														parseFloat(
															bs.credit / member.length / remarks.length
														).toFixed(2)
													) *
														member.length *
														remarks.length;
											}
											data.push({
												category: scat,
												// date: dayjs(bs.date, "DD-MMM-YYYY").format("D/M/YYYY"),
												date: dayjs(bs.date, "DD-MMM-YYYY").format(
													"YYYY-MM-DD"
												),
												amount:
													parseFloat(
														parseFloat(
															bs.credit / member.length / remarks.length
														).toFixed(2)
													) + leftover,
												entity: mem
													? mem.memberID
													: (
															(descriptionName &&
																descriptionName.toUpperCase()) ||
															(scat.toLowerCase() === "tithely" ||
															scat.toLowerCase() === "paypal" ||
															scat.toLowerCase() === "iras" ||
															scat.toLowerCase() === "interest"
																? scat
																: "NO NAME")
													  ).replace(/\s+/g, " "),
												remarks: `${
													remark.month
														? dayjs(
																`01-${remark.month.substr(0, 3)}-${
																	remark.year
																}`,
																"DD-MMM-YY"
														  ).format("YYYY-MM")
														: dayjs(
																`01-${remark.substr(0, 3)}-${dayjs()
																	.format("YY")
																	.toString()}`,
																"DD-MMM-YY"
														  ).format("YYYY-MM")
												}`,
												cong: scong,
												region: sregion,
												description2: bs.description2,
												id: `IB${bs.workdate.substr(2)}-${bs.week * 10000 +
													data.length +
													1}`,
												status: "Received",
												blank: ""
											});
									  })
									: data.push({
											category: scat,
											// date: dayjs(bs.date, "DD-MMM-YYYY").format("D/M/YYYY"),
											date: dayjs(bs.date, "DD-MMM-YYYY").format("YYYY-MM-DD"),
											amount: parseFloat(
												parseFloat(bs.credit / member.length).toFixed(2)
											),
											entity: mem
												? mem.memberID
												: (
														(descriptionName &&
															descriptionName.toUpperCase()) ||
														(scat.toLowerCase() === "tithely" ||
														scat.toLowerCase() === "paypal" ||
														scat.toLowerCase() === "iras" ||
														scat.toLowerCase() === "interest"
															? scat
															: d.indexOf("GOVERNMENT PAYMENT") > -1
															? d.replace(" GOVERNMENT PAYMENT", "")
															: "NO NAME")
												  ).replace(/\s+/g, " "),
											remarks: dayjs(bs.date, "DD-MMM-YYYY")
												.format("YYYY-MM")
												.toString(),
											cong: scong,
											region: sregion,
											description2: bs.description2,
											id: `IB${bs.workdate.substr(2)}-${bs.week * 10000 +
												data.length +
												1}`,
											status: "Received",
											blank: ""
									  });
						  })
						: member && typeof member === "string"
						? remarks && remarks.length > 0
							? remarks.forEach((remark, index) => {
									let leftover = 0;
									if (index === 0) {
										leftover =
											bs.credit -
											parseFloat(
												parseFloat(bs.credit / remarks.length).toFixed(2)
											) *
												remarks.length;
									}
									data.push({
										category: scat,
										// date: dayjs(bs.date, "DD-MMM-YYYY").format("D/M/YYYY"),
										date: dayjs(bs.date, "DD-MMM-YYYY").format("YYYY-MM-DD"),
										amount:
											parseFloat(
												parseFloat(bs.credit / remarks.length).toFixed(2)
											) + leftover,
										entity: member.replace(/\s+/g, " "),
										remarks: `${
											remark.month
												? dayjs(
														`01-${remark.month.substr(0, 3)}-${remark.year}`,
														"DD-MMM-YY"
												  ).format("YYYY-MM")
												: dayjs(
														`01-${remark.substr(0, 3)}-${dayjs()
															.format("YY")
															.toString()}`,
														"DD-MMM-YY"
												  ).format("YYYY-MM")
										}`,
										cong:
											descriptionName === "cheque" ? "BLANK" : "Unidentified",
										region: descriptionName === "cheque" ? "BLANK" : "Others",
										description2: bs.description2,
										id: `IB${bs.workdate.substr(2)}-${bs.week * 10000 +
											data.length +
											1}`,
										status: "Received",
										blank: ""
									});
							  })
							: data.push({
									category: scat,
									// date: dayjs(bs.date, "DD-MMM-YYYY").format("D/M/YYYY"),
									date: dayjs(bs.date, "DD-MMM-YYYY").format("YYYY-MM-DD"),
									amount: parseFloat(parseFloat(bs.credit).toFixed(2)),
									entity: member.replace(/\s+/g, " "),
									remarks: dayjs(bs.date, "DD-MMM-YYYY")
										.format("YYYY-MM")
										.toString(),
									cong: descriptionName === "cheque" ? "BLANK" : "Unidentified",
									region: descriptionName === "cheque" ? "BLANK" : "Others",
									description2: bs.description2,
									id: `IB${bs.workdate.substr(2)}-${bs.week * 10000 +
										data.length +
										1}`,
									status: "Received",
									blank: ""
							  })
						: remarks && remarks.length > 0
						? remarks.forEach((remark, index) => {
								let leftover = 0;
								if (index === 0) {
									leftover =
										bs.credit -
										parseFloat(
											parseFloat(bs.credit / remarks.length).toFixed(2)
										) *
											remarks.length;
								}
								data.push({
									category: scat,
									// date: dayjs(bs.date, "DD-MMM-YYYY").format("D/M/YYYY"),
									date: dayjs(bs.date, "DD-MMM-YYYY").format("YYYY-MM-DD"),
									amount:
										parseFloat(
											parseFloat(bs.credit / remarks.length).toFixed(2)
										) + leftover,
									entity: (
										(descriptionName && descriptionName.toUpperCase()) ||
										(scat.toLowerCase() === "tithely" ||
										scat.toLowerCase() === "paypal" ||
										scat.toLowerCase() === "iras" ||
										scat.toLowerCase() === "interest"
											? scat
											: d.indexOf("GOVERNMENT PAYMENT") > -1
											? d.replace(" GOVERNMENT PAYMENT", "")
											: "NO NAME")
									).replace(/\s+/g, " "),
									remarks: `${
										remark.month
											? dayjs(
													`01-${remark.month.substr(0, 3)}-${remark.year}`,
													"DD-MMM-YY"
											  ).format("YYYY-MM")
											: dayjs(
													`01-${remark.substr(0, 3)}-${dayjs()
														.format("YY")
														.toString()}`,
													"DD-MMM-YY"
											  ).format("YYYY-MM")
									}`,
									cong: descriptionName === "cheque" ? "BLANK" : "Unidentified",
									region: descriptionName === "cheque" ? "BLANK" : "Others",
									description2: bs.description2,
									id: `IB${bs.workdate.substr(2)}-${bs.week * 10000 +
										data.length +
										1}`,
									status: "Received",
									blank: ""
								});
						  })
						: data.push({
								category: scat,
								// date: dayjs(bs.date, "DD-MMM-YYYY").format("D/M/YYYY"),
								date: dayjs(bs.date, "DD-MMM-YYYY").format("YYYY-MM-DD"),
								amount: parseFloat(parseFloat(bs.credit).toFixed(2)),
								entity: (
									(descriptionName && descriptionName.toUpperCase()) ||
									(scat.toLowerCase() === "tithely" ||
									scat.toLowerCase() === "paypal" ||
									scat.toLowerCase() === "iras" ||
									scat.toLowerCase() === "interest"
										? scat
										: d.indexOf("GOVERNMENT PAYMENT") > -1
										? d.replace(" GOVERNMENT PAYMENT", "")
										: "NO NAME")
								).replace(/\s+/g, " "),
								remarks: dayjs(bs.date, "DD-MMM-YYYY")
									.format("YYYY-MM")
									.toString(),
								cong: descriptionName === "cheque" ? "BLANK" : "Unidentified",
								region: descriptionName === "cheque" ? "BLANK" : "Others",
								description2: bs.description2,
								id: `IB${bs.workdate.substr(2)}-${bs.week * 10000 +
									data.length +
									1}`,
								status: "Received",
								blank: ""
						  });

					const scong =
						member && member.length > 0
							? member[0].congregation
							: "Unidentified";
					const sregion =
						member && member.length > 0 ? member[0].region : "Others";

					if (
						!summary.find(
							(summ) =>
								summ.category === scat &&
								summ.cong === scong &&
								summ.region === sregion
						)
					) {
						summary.push({
							category: scat,
							cong: scong,
							region: sregion,
							total: 0
						});
					}

					const ind = summary.findIndex(
						(summ) =>
							summ.category === scat &&
							summ.cong === scong &&
							summ.region === sregion
					);

					summary[ind].total += parseFloat(bs.credit);
				}
			});

		return {
			data,
			summary: summary.map((summ) => ({
				...summ,
				total: parseFloat(summ.total).toFixed(2)
			}))
		};
	};

	const tithelyTable = () => {
		const data = [];
		const summary = [];

		tithelies &&
			tithelies.forEach((bs) => {
				const member = memberSearch(
					bs.memberID !== "" ? bs.memberID : `${bs.firstName} ${bs.lastName}`,
					`${bs.firstName} ${bs.lastName}`
				);
				const inp = inps
					.sort((a, b) => b.title.length - a.title.length)
					.find((input) => {
						return (
							bs.type.toLowerCase().indexOf(input.title.toLowerCase()) > -1
						);
					});
				const category = inp
					? cats
							.sort((a, b) => b.title.length - a.title.length)
							.find((cat) => cat.id === inp.category)
					: null;

				const remarks = months
					.filter(
						(month) =>
							` ${bs.memo} `
								.toLowerCase()
								.indexOf(
									` ${month.toLowerCase()} ${lastYear
										.format("YYYY")
										.toString()} `
								) > -1 ||
							` ${bs.type} `
								.toLowerCase()
								.indexOf(
									` ${month.toLowerCase()} ${lastYear
										.format("YYYY")
										.toString()} `
								) > -1 ||
							` ${bs.memo} `
								.toLowerCase()
								.indexOf(
									` ${month.toLowerCase()}-${lastYear
										.format("YYYY")
										.toString()} `
								) > -1 ||
							` ${bs.type} `
								.toLowerCase()
								.indexOf(
									` ${month.toLowerCase()}-${lastYear
										.format("YYYY")
										.toString()} `
								) > -1 ||
							` ${bs.memo} `
								.toLowerCase()
								.indexOf(
									` ${month.toLowerCase()} ${lastYear.format("YY").toString()} `
								) > -1 ||
							` ${bs.type} `
								.toLowerCase()
								.indexOf(
									` ${month.toLowerCase()} ${lastYear.format("YY").toString()} `
								) > -1 ||
							` ${bs.memo} `
								.toLowerCase()
								.indexOf(
									` ${month.toLowerCase()}-${lastYear.format("YY").toString()} `
								) > -1 ||
							` ${bs.type} `
								.toLowerCase()
								.indexOf(
									` ${month.toLowerCase()}-${lastYear.format("YY").toString()} `
								) > -1 ||
							` ${bs.type} `
								.toLowerCase()
								.indexOf(
									` ${month.substr(0, 4).toLowerCase()} ${lastYear
										.format("YYYY")
										.toString()} `
								) > -1 ||
							` ${bs.memo} `
								.toLowerCase()
								.indexOf(
									` ${month.substr(0, 4).toLowerCase()} ${lastYear
										.format("YYYY")
										.toString()} `
								) > -1 ||
							` ${bs.type} `
								.toLowerCase()
								.indexOf(
									` ${month.substr(0, 4).toLowerCase()}-${lastYear
										.format("YYYY")
										.toString()} `
								) > -1 ||
							` ${bs.memo} `
								.toLowerCase()
								.indexOf(
									` ${month.substr(0, 4).toLowerCase()}-${lastYear
										.format("YYYY")
										.toString()} `
								) > -1 ||
							` ${bs.type} `
								.toLowerCase()
								.indexOf(
									` ${month.substr(0, 4).toLowerCase()} ${lastYear
										.format("YY")
										.toString()} `
								) > -1 ||
							` ${bs.memo} `
								.toLowerCase()
								.indexOf(
									` ${month.substr(0, 4).toLowerCase()} ${lastYear
										.format("YY")
										.toString()} `
								) > -1 ||
							` ${bs.type} `
								.toLowerCase()
								.indexOf(
									` ${month.substr(0, 4).toLowerCase()}-${lastYear
										.format("YY")
										.toString()} `
								) > -1 ||
							` ${bs.memo} `
								.toLowerCase()
								.indexOf(
									` ${month.substr(0, 4).toLowerCase()}-${lastYear
										.format("YY")
										.toString()} `
								) > -1 ||
							` ${bs.type} `
								.toLowerCase()
								.indexOf(
									` ${month.substr(0, 3).toLowerCase()} ${lastYear
										.format("YYYY")
										.toString()} `
								) > -1 ||
							` ${bs.memo} `
								.toLowerCase()
								.indexOf(
									` ${month.substr(0, 3).toLowerCase()} ${lastYear
										.format("YYYY")
										.toString()} `
								) > -1 ||
							` ${bs.type} `
								.toLowerCase()
								.indexOf(
									` ${month.substr(0, 3).toLowerCase()}-${lastYear
										.format("YYYY")
										.toString()} `
								) > -1 ||
							` ${bs.memo} `
								.toLowerCase()
								.indexOf(
									` ${month.substr(0, 3).toLowerCase()}-${lastYear
										.format("YYYY")
										.toString()} `
								) > -1 ||
							` ${bs.type} `
								.toLowerCase()
								.indexOf(
									` ${month.substr(0, 3).toLowerCase()} ${lastYear
										.format("YY")
										.toString()} `
								) > -1 ||
							` ${bs.memo} `
								.toLowerCase()
								.indexOf(
									` ${month.substr(0, 3).toLowerCase()} ${lastYear
										.format("YY")
										.toString()} `
								) > -1 ||
							` ${bs.type} `
								.toLowerCase()
								.indexOf(
									` ${month.substr(0, 3).toLowerCase()}-${lastYear
										.format("YY")
										.toString()} `
								) > -1 ||
							` ${bs.memo} `
								.toLowerCase()
								.indexOf(
									` ${month.substr(0, 3).toLowerCase()}-${lastYear
										.format("YY")
										.toString()} `
								) > -1 ||
							` ${bs.memo} `
								.toLowerCase()
								.indexOf(
									` ${month.toLowerCase()} ${nextYear
										.format("YYYY")
										.toString()} `
								) > -1 ||
							` ${bs.type} `
								.toLowerCase()
								.indexOf(
									` ${month.toLowerCase()} ${nextYear
										.format("YYYY")
										.toString()} `
								) > -1 ||
							` ${bs.memo} `
								.toLowerCase()
								.indexOf(
									` ${month.toLowerCase()}-${nextYear
										.format("YYYY")
										.toString()} `
								) > -1 ||
							` ${bs.type} `
								.toLowerCase()
								.indexOf(
									` ${month.toLowerCase()}-${nextYear
										.format("YYYY")
										.toString()} `
								) > -1 ||
							` ${bs.memo} `
								.toLowerCase()
								.indexOf(
									` ${month.toLowerCase()} ${nextYear.format("YY").toString()} `
								) > -1 ||
							` ${bs.type} `
								.toLowerCase()
								.indexOf(
									` ${month.toLowerCase()} ${nextYear.format("YY").toString()} `
								) > -1 ||
							` ${bs.memo} `
								.toLowerCase()
								.indexOf(
									` ${month.toLowerCase()}-${nextYear.format("YY").toString()} `
								) > -1 ||
							` ${bs.type} `
								.toLowerCase()
								.indexOf(
									` ${month.toLowerCase()}-${nextYear.format("YY").toString()} `
								) > -1 ||
							` ${bs.type} `
								.toLowerCase()
								.indexOf(
									` ${month.substr(0, 4).toLowerCase()} ${nextYear
										.format("YYYY")
										.toString()} `
								) > -1 ||
							` ${bs.memo} `
								.toLowerCase()
								.indexOf(
									` ${month.substr(0, 4).toLowerCase()} ${nextYear
										.format("YYYY")
										.toString()} `
								) > -1 ||
							` ${bs.type} `
								.toLowerCase()
								.indexOf(
									` ${month.substr(0, 4).toLowerCase()}-${nextYear
										.format("YYYY")
										.toString()} `
								) > -1 ||
							` ${bs.memo} `
								.toLowerCase()
								.indexOf(
									` ${month.substr(0, 4).toLowerCase()}-${nextYear
										.format("YYYY")
										.toString()} `
								) > -1 ||
							` ${bs.type} `
								.toLowerCase()
								.indexOf(
									` ${month.substr(0, 4).toLowerCase()} ${nextYear
										.format("YY")
										.toString()} `
								) > -1 ||
							` ${bs.memo} `
								.toLowerCase()
								.indexOf(
									` ${month.substr(0, 4).toLowerCase()} ${nextYear
										.format("YY")
										.toString()} `
								) > -1 ||
							` ${bs.type} `
								.toLowerCase()
								.indexOf(
									` ${month.substr(0, 4).toLowerCase()}-${nextYear
										.format("YY")
										.toString()} `
								) > -1 ||
							` ${bs.memo} `
								.toLowerCase()
								.indexOf(
									` ${month.substr(0, 4).toLowerCase()}-${nextYear
										.format("YY")
										.toString()} `
								) > -1 ||
							` ${bs.type} `
								.toLowerCase()
								.indexOf(
									` ${month.substr(0, 3).toLowerCase()} ${nextYear
										.format("YYYY")
										.toString()} `
								) > -1 ||
							` ${bs.memo} `
								.toLowerCase()
								.indexOf(
									` ${month.substr(0, 3).toLowerCase()} ${nextYear
										.format("YYYY")
										.toString()} `
								) > -1 ||
							` ${bs.type} `
								.toLowerCase()
								.indexOf(
									` ${month.substr(0, 3).toLowerCase()}-${nextYear
										.format("YYYY")
										.toString()} `
								) > -1 ||
							` ${bs.memo} `
								.toLowerCase()
								.indexOf(
									` ${month.substr(0, 3).toLowerCase()}-${nextYear
										.format("YYYY")
										.toString()} `
								) > -1 ||
							` ${bs.type} `
								.toLowerCase()
								.indexOf(
									` ${month.substr(0, 3).toLowerCase()} ${nextYear
										.format("YY")
										.toString()} `
								) > -1 ||
							` ${bs.memo} `
								.toLowerCase()
								.indexOf(
									` ${month.substr(0, 3).toLowerCase()} ${nextYear
										.format("YY")
										.toString()} `
								) > -1 ||
							` ${bs.type} `
								.toLowerCase()
								.indexOf(
									` ${month.substr(0, 3).toLowerCase()}-${nextYear
										.format("YY")
										.toString()} `
								) > -1 ||
							` ${bs.memo} `
								.toLowerCase()
								.indexOf(
									` ${month.substr(0, 3).toLowerCase()}-${nextYear
										.format("YY")
										.toString()} `
								) > -1
					)
					.map((month) => {
						if (
							` ${bs.memo} `
								.toLowerCase()
								.indexOf(
									` ${month.toLowerCase()} ${lastYear
										.format("YYYY")
										.toString()} `
								) > -1 ||
							` ${bs.type} `
								.toLowerCase()
								.indexOf(
									` ${month.toLowerCase()} ${lastYear
										.format("YYYY")
										.toString()} `
								) > -1 ||
							` ${bs.memo} `
								.toLowerCase()
								.indexOf(
									` ${month.toLowerCase()}-${lastYear
										.format("YYYY")
										.toString()} `
								) > -1 ||
							` ${bs.type} `
								.toLowerCase()
								.indexOf(
									` ${month.toLowerCase()}-${lastYear
										.format("YYYY")
										.toString()} `
								) > -1 ||
							` ${bs.memo} `
								.toLowerCase()
								.indexOf(
									` ${month.toLowerCase()} ${lastYear.format("YY").toString()} `
								) > -1 ||
							` ${bs.type} `
								.toLowerCase()
								.indexOf(
									` ${month.toLowerCase()} ${lastYear.format("YY").toString()} `
								) > -1 ||
							` ${bs.memo} `
								.toLowerCase()
								.indexOf(
									` ${month.toLowerCase()}-${lastYear.format("YY").toString()} `
								) > -1 ||
							` ${bs.type} `
								.toLowerCase()
								.indexOf(
									` ${month.toLowerCase()}-${lastYear.format("YY").toString()} `
								) > -1 ||
							` ${bs.type} `
								.toLowerCase()
								.indexOf(
									` ${month.substr(0, 4).toLowerCase()} ${lastYear
										.format("YYYY")
										.toString()} `
								) > -1 ||
							` ${bs.memo} `
								.toLowerCase()
								.indexOf(
									` ${month.substr(0, 4).toLowerCase()} ${lastYear
										.format("YYYY")
										.toString()} `
								) > -1 ||
							` ${bs.type} `
								.toLowerCase()
								.indexOf(
									` ${month.substr(0, 4).toLowerCase()}-${lastYear
										.format("YYYY")
										.toString()} `
								) > -1 ||
							` ${bs.memo} `
								.toLowerCase()
								.indexOf(
									` ${month.substr(0, 4).toLowerCase()}-${lastYear
										.format("YYYY")
										.toString()} `
								) > -1 ||
							` ${bs.type} `
								.toLowerCase()
								.indexOf(
									` ${month.substr(0, 4).toLowerCase()} ${lastYear
										.format("YY")
										.toString()} `
								) > -1 ||
							` ${bs.memo} `
								.toLowerCase()
								.indexOf(
									` ${month.substr(0, 4).toLowerCase()} ${lastYear
										.format("YY")
										.toString()} `
								) > -1 ||
							` ${bs.type} `
								.toLowerCase()
								.indexOf(
									` ${month.substr(0, 4).toLowerCase()}-${lastYear
										.format("YY")
										.toString()} `
								) > -1 ||
							` ${bs.memo} `
								.toLowerCase()
								.indexOf(
									` ${month.substr(0, 4).toLowerCase()}-${lastYear
										.format("YY")
										.toString()} `
								) > -1 ||
							` ${bs.type} `
								.toLowerCase()
								.indexOf(
									` ${month.substr(0, 3).toLowerCase()} ${lastYear
										.format("YYYY")
										.toString()} `
								) > -1 ||
							` ${bs.memo} `
								.toLowerCase()
								.indexOf(
									` ${month.substr(0, 3).toLowerCase()} ${lastYear
										.format("YYYY")
										.toString()} `
								) > -1 ||
							` ${bs.type} `
								.toLowerCase()
								.indexOf(
									` ${month.substr(0, 3).toLowerCase()}-${lastYear
										.format("YYYY")
										.toString()} `
								) > -1 ||
							` ${bs.memo} `
								.toLowerCase()
								.indexOf(
									` ${month.substr(0, 3).toLowerCase()}-${lastYear
										.format("YYYY")
										.toString()} `
								) > -1 ||
							` ${bs.type} `
								.toLowerCase()
								.indexOf(
									` ${month.substr(0, 3).toLowerCase()} ${lastYear
										.format("YY")
										.toString()} `
								) > -1 ||
							` ${bs.memo} `
								.toLowerCase()
								.indexOf(
									` ${month.substr(0, 3).toLowerCase()} ${lastYear
										.format("YY")
										.toString()} `
								) > -1 ||
							` ${bs.type} `
								.toLowerCase()
								.indexOf(
									` ${month.substr(0, 3).toLowerCase()}-${lastYear
										.format("YY")
										.toString()} `
								) > -1 ||
							` ${bs.memo} `
								.toLowerCase()
								.indexOf(
									` ${month.substr(0, 3).toLowerCase()}-${lastYear
										.format("YY")
										.toString()} `
								) > -1
						) {
							return { month, year: lastYear.format("YY").toString() };
						} else if (
							` ${bs.memo} `
								.toLowerCase()
								.indexOf(
									` ${month.toLowerCase()} ${nextYear
										.format("YYYY")
										.toString()} `
								) > -1 ||
							` ${bs.type} `
								.toLowerCase()
								.indexOf(
									` ${month.toLowerCase()} ${nextYear
										.format("YYYY")
										.toString()} `
								) > -1 ||
							` ${bs.memo} `
								.toLowerCase()
								.indexOf(
									` ${month.toLowerCase()}-${nextYear
										.format("YYYY")
										.toString()} `
								) > -1 ||
							` ${bs.type} `
								.toLowerCase()
								.indexOf(
									` ${month.toLowerCase()}-${nextYear
										.format("YYYY")
										.toString()} `
								) > -1 ||
							` ${bs.memo} `
								.toLowerCase()
								.indexOf(
									` ${month.toLowerCase()} ${nextYear.format("YY").toString()} `
								) > -1 ||
							` ${bs.type} `
								.toLowerCase()
								.indexOf(
									` ${month.toLowerCase()} ${nextYear.format("YY").toString()} `
								) > -1 ||
							` ${bs.memo} `
								.toLowerCase()
								.indexOf(
									` ${month.toLowerCase()}-${nextYear.format("YY").toString()} `
								) > -1 ||
							` ${bs.type} `
								.toLowerCase()
								.indexOf(
									` ${month.toLowerCase()}-${nextYear.format("YY").toString()} `
								) > -1 ||
							` ${bs.type} `
								.toLowerCase()
								.indexOf(
									` ${month.substr(0, 4).toLowerCase()} ${nextYear
										.format("YYYY")
										.toString()} `
								) > -1 ||
							` ${bs.memo} `
								.toLowerCase()
								.indexOf(
									` ${month.substr(0, 4).toLowerCase()} ${nextYear
										.format("YYYY")
										.toString()} `
								) > -1 ||
							` ${bs.type} `
								.toLowerCase()
								.indexOf(
									` ${month.substr(0, 4).toLowerCase()}-${nextYear
										.format("YYYY")
										.toString()} `
								) > -1 ||
							` ${bs.memo} `
								.toLowerCase()
								.indexOf(
									` ${month.substr(0, 4).toLowerCase()}-${nextYear
										.format("YYYY")
										.toString()} `
								) > -1 ||
							` ${bs.type} `
								.toLowerCase()
								.indexOf(
									` ${month.substr(0, 4).toLowerCase()} ${nextYear
										.format("YY")
										.toString()} `
								) > -1 ||
							` ${bs.memo} `
								.toLowerCase()
								.indexOf(
									` ${month.substr(0, 4).toLowerCase()} ${nextYear
										.format("YY")
										.toString()} `
								) > -1 ||
							` ${bs.type} `
								.toLowerCase()
								.indexOf(
									` ${month.substr(0, 4).toLowerCase()}-${nextYear
										.format("YY")
										.toString()} `
								) > -1 ||
							` ${bs.memo} `
								.toLowerCase()
								.indexOf(
									` ${month.substr(0, 4).toLowerCase()}-${nextYear
										.format("YY")
										.toString()} `
								) > -1 ||
							` ${bs.type} `
								.toLowerCase()
								.indexOf(
									` ${month.substr(0, 3).toLowerCase()} ${nextYear
										.format("YYYY")
										.toString()} `
								) > -1 ||
							` ${bs.memo} `
								.toLowerCase()
								.indexOf(
									` ${month.substr(0, 3).toLowerCase()} ${nextYear
										.format("YYYY")
										.toString()} `
								) > -1 ||
							` ${bs.type} `
								.toLowerCase()
								.indexOf(
									` ${month.substr(0, 3).toLowerCase()}-${nextYear
										.format("YYYY")
										.toString()} `
								) > -1 ||
							` ${bs.memo} `
								.toLowerCase()
								.indexOf(
									` ${month.substr(0, 3).toLowerCase()}-${nextYear
										.format("YYYY")
										.toString()} `
								) > -1 ||
							` ${bs.type} `
								.toLowerCase()
								.indexOf(
									` ${month.substr(0, 3).toLowerCase()} ${nextYear
										.format("YY")
										.toString()} `
								) > -1 ||
							` ${bs.memo} `
								.toLowerCase()
								.indexOf(
									` ${month.substr(0, 3).toLowerCase()} ${nextYear
										.format("YY")
										.toString()} `
								) > -1 ||
							` ${bs.type} `
								.toLowerCase()
								.indexOf(
									` ${month.substr(0, 3).toLowerCase()}-${nextYear
										.format("YY")
										.toString()} `
								) > -1 ||
							` ${bs.memo} `
								.toLowerCase()
								.indexOf(
									` ${month.substr(0, 3).toLowerCase()}-${nextYear
										.format("YY")
										.toString()} `
								) > -1
						) {
							return { month, year: nextYear.format("YY").toString() };
						}

						return { month, year: curYear.format("YY").toString() };
					});

				const scat = category ? category.title : "Tithe";
				let backdate = bs.backdate;
				if (bs.backdate) {
					const bdate =
						bs.backdate.indexOf("/") > -1
							? bs.backdate.split("/")
							: bs.backdate.indexOf("-") > -1
							? bs.backdate.split("-")
							: dayjs()
									.format("MMM/YY")
									.split("/");

					const parsedbdate =
						"01/" +
						(bdate[0].length === 3
							? bdate[0]
							: bdate[0].length === 2
							? months[parseInt(bdate[0]) - 1].substr(0, 3)
							: bdate[1].length === 2 && bdate[1] !== dayjs().format("YY")
							? months[parseInt(bdate[1]) - 1].substr(0, 3)
							: months[parseInt(bdate[0]) - 1].substr(0, 3)) +
						"/" +
						(bdate[1].length === 4
							? bdate[1]
							: bdate[1] !== dayjs().format("YYYY") ||
							  bdate[1] !==
									dayjs()
										.add(1, "year")
										.format("YYYY") ||
							  bdate[1] !==
									dayjs()
										.subtract(1, "year")
										.format("YYYY") ||
							  bdate[1] !== dayjs().format("YY") ||
							  bdate[1] !==
									dayjs()
										.add(1, "year")
										.format("YY") ||
							  bdate[1] !==
									dayjs()
										.subtract(1, "year")
										.format("YY")
							? dayjs().format("YYYY")
							: bdate[1]);
					backdate = parsedbdate;
				}

				member && member.length > 0 && Array.isArray(member)
					? member.forEach((mem) => {
							const scong = mem ? mem.congregation : "Unidentified";
							const sregion = mem ? mem.region : "Others";

							remarks && remarks.length > 0
								? remarks.forEach((remark) => {
										data.push({
											category: scat,
											// date: dayjs(bs.date, "DD/MMM/YYYY", true)
											// 	.format("D/M/YYYY")
											// 	.toString(),
											date: dayjs(bs.date, "DD/MMM/YYYY", true)
												.format("YYYY-MM-DD")
												.toString(),
											amount: parseFloat(
												parseFloat(bs.amount / remarks.length).toFixed(2)
											),
											entity: mem
												? mem.memberID
												: `${bs.firstName} ${bs.lastName}`,
											remarks: remark.month
												? dayjs(
														`01-${remark.month.substr(0, 3)}-${remark.year}`,
														"DD-MMM-YY"
												  ).format("YYYY-MM")
												: dayjs(
														`01-${remark.substr(0, 3)}-${dayjs(
															bs.date,
															"DD/MMM/YYYY",
															true
														)
															.format("YY")
															.toString()}`,
														"DD-MMM-YY"
												  ).format("YYYY-MM"),
											cong: scong,
											region: sregion,
											description2: bs.description2,
											id: bs.id,
											type: bs.type,
											checkIbankNets: bs.checkIbankNets,
											memo: bs.memo,
											backdate: bs.backdate,
											status: "Received",
											blank: ""
										});
								  })
								: data.push({
										category: scat,
										// date: dayjs(bs.date, "DD/MMM/YYYY", true)
										// 	.format("D/M/YYYY")
										// 	.toString(),
										date: dayjs(bs.date, "DD/MMM/YYYY", true)
											.format("YYYY-MM-DD")
											.toString(),
										amount: parseFloat(parseFloat(bs.amount).toFixed(2)),
										entity: mem
											? mem.memberID
											: `${bs.firstName} ${bs.lastName}`,
										remarks:
											bs.backdate && bs.backdate !== ""
												? dayjs(backdate, "DD/MMM/YYYY", true)
														.format("YYYY-MM")
														.toString()
												: dayjs(bs.date, "DD/MMM/YYYY", true)
														.format("YYYY-MM")
														.toString(),
										cong: scong,
										region: sregion,
										description2: bs.description2,
										id: bs.id,
										type: bs.type,
										checkIbankNets: bs.checkIbankNets,
										memo: bs.memo,
										backdate: bs.backdate,
										status: "Received",
										blank: ""
								  });
					  })
					: member && typeof member === "string"
					? remarks && remarks.length > 0
						? remarks.forEach((remark) => {
								data.push({
									category: scat,
									// date: dayjs(bs.date, "DD/MMM/YYYY", true)
									// 	.format("D/M/YYYY")
									// 	.toString(),
									date: dayjs(bs.date, "DD/MMM/YYYY", true)
										.format("YYYY-MM-DD")
										.toString(),
									amount: parseFloat(
										parseFloat(bs.amount / remarks.length).toFixed(2)
									),
									entity: member.replace(/\s+/g, " "),
									remarks: remark.month
										? dayjs(
												`01-${remark.month.substr(0, 3)}-${remark.year}`,
												"DD-MMM-YY"
										  ).format("YYYY-MM")
										: dayjs(
												`01-${remark.substr(0, 3)}-${dayjs(
													bs.date,
													"DD/MMM/YYYY",
													true
												)
													.format("YY")
													.toString()}`,
												"DD-MMM-YY"
										  ).format("YYYY-MM"),
									cong: "Unidentified",
									region: "Others",
									description2: bs.description2,
									id: bs.id,
									type: bs.type,
									checkIbankNets: bs.checkIbankNets,
									memo: bs.memo,
									backdate: bs.backdate,
									status: "Received",
									blank: ""
								});
						  })
						: data.push({
								category: scat,
								// date: dayjs(bs.date, "DD/MMM/YYYY", true)
								// 	.format("D/M/YYYY")
								// 	.toString(),
								date: dayjs(bs.date, "DD/MMM/YYYY", true)
									.format("YYYY-MM-DD")
									.toString(),
								amount: parseFloat(parseFloat(bs.amount).toFixed(2)),
								entity: member.replace(/\s+/g, " "),
								remarks:
									bs.backdate && bs.backdate !== ""
										? dayjs(backdate, "DD/MMM/YYYY", true)
												.format("YYYY-MM")
												.toString()
										: dayjs(bs.date, "DD/MMM/YYYY", true)
												.format("YYYY-MM")
												.toString(),
								cong: "Unidentified",
								region: "Others",
								description2: bs.description2,
								id: bs.id,
								type: bs.type,
								checkIbankNets: bs.checkIbankNets,
								memo: bs.memo,
								backdate: bs.backdate,
								status: "Received",
								blank: ""
						  })
					: remarks && remarks.length > 0
					? remarks.forEach((remark) => {
							data.push({
								category: scat,
								// date: dayjs(bs.date, "DD/MMM/YYYY", true)
								// 	.format("D/M/YYYY")
								// 	.toString(),
								date: dayjs(bs.date, "DD/MMM/YYYY", true)
									.format("YYYY-MM-DD")
									.toString(),
								amount: parseFloat(
									parseFloat(bs.amount / remarks.length).toFixed(2)
								),
								entity: `${bs.firstName} ${bs.lastName}`,
								remarks: remark.month
									? dayjs(
											`01-${remark.month.substr(0, 3)}-${remark.year}`,
											"DD-MMM-YY"
									  ).format("YYYY-MM")
									: dayjs(
											`01-${remark.substr(0, 3)}-${dayjs(
												bs.date,
												"DD/MMM/YYYY",
												true
											)
												.format("YY")
												.toString()}`,
											"DD-MMM-YY"
									  ).format("YYYY-MM"),
								cong: "Unidentified",
								region: "Others",
								description2: bs.description2,
								id: bs.id,
								type: bs.type,
								checkIbankNets: bs.checkIbankNets,
								memo: bs.memo,
								backdate: bs.backdate,
								status: "Received",
								blank: ""
							});
					  })
					: data.push({
							category: scat,
							// date: dayjs(bs.date, "DD/MMM/YYYY", true)
							// 	.format("D/M/YYYY")
							// 	.toString(),
							date: dayjs(bs.date, "DD/MMM/YYYY", true)
								.format("YYYY-MM-DD")
								.toString(),
							amount: parseFloat(parseFloat(bs.amount).toFixed(2)),
							entity: `${bs.firstName} ${bs.lastName}`,
							remarks:
								bs.backdate && bs.backdate !== ""
									? dayjs(backdate, "DD/MMM/YYYY", true)
											.format("YYYY-MM")
											.toString()
									: dayjs(bs.date, "DD/MMM/YYYY", true)
											.format("YYYY-MM")
											.toString(),
							cong: "Unidentified",
							region: "Others",
							description2: bs.description2,
							id: bs.id,
							type: bs.type,
							checkIbankNets: bs.checkIbankNets,
							memo: bs.memo,
							backdate: bs.backdate,
							status: "Received",
							blank: ""
					  });

				const scong =
					member && member.length > 0 ? member[0].congregation : "Unidentified";
				const sregion =
					member && member.length > 0 ? member[0].region : "Others";

				if (
					!summary.find(
						(summ) =>
							summ.category === scat &&
							summ.cong === scong &&
							summ.region === sregion
					)
				) {
					summary.push({
						category: scat,
						cong: scong,
						region: sregion,
						total: 0
					});
				}

				const ind = summary.findIndex(
					(summ) =>
						summ.category === scat &&
						summ.cong === scong &&
						summ.region === sregion
				);

				summary[ind].total += parseFloat(bs.amount);
			});

		return {
			data,
			summary: summary.map((summ) => ({
				...summ,
				total: parseFloat(summ.total).toFixed(2)
			}))
		};
	};

	return (
		<View>
			<Container>
				<Box flex>
					<Title>Extractor</Title>

					<Tabs defaultActiveKey="1">
						<TabPane tab="Bank Statement" key="1">
							<Title fontSize={20}>Bank Statement</Title>

							{mems &&
							mems.length > 0 &&
							bankStatements &&
							bankStatements &&
							bankStatements.length > 0 ? (
								bankStatementData &&
								bankStatementData.data &&
								bankStatementData.data.length > 0 ? (
									<>
										<ExcelFile
											element={
												<Button type="primary">
													Download {`GIB${bankStatements[0].workdate}`}
												</Button>
											}
											filename={`GIB${bankStatements[0].workdate}`}
										>
											<ExcelSheet
												data={bankStatementData.data}
												name="Bank Statement"
											>
												{/* <ExcelColumn label="Category" value="category" />
												<ExcelColumn label="EntityKey" value="entity" />
												<ExcelColumn label="Date" value="date" />
												<ExcelColumn label="Remarks" value="remarks" />
												<ExcelColumn label="Amount" value="amount" />
												<ExcelColumn label="ID" value="id" />
												<ExcelColumn label="Cong" value="cong" />
												<ExcelColumn label="Region" value="region" />
												<ExcelColumn
													label="Transaction Description 2"
													value="description2"
												/> */}
												<ExcelColumn label="Category" value="category" />
												<ExcelColumn label="Entity Key" value="entity" />
												<ExcelColumn label="Date" value="date" />
												<ExcelColumn label="For Period" value="remarks" />
												<ExcelColumn label="Amount" value="amount" />
												<ExcelColumn label="ID" value="id" />
												<ExcelColumn label="Cong" value="cong" />
												<ExcelColumn label="Region" value="region" />
												<ExcelColumn
													label="Transaction Description 2"
													value="description2"
												/>
												<ExcelColumn label="Remarks" value="blank" />
												<ExcelColumn label="Status" value="status" />
											</ExcelSheet>

											<ExcelSheet
												data={bankStatementData.summary}
												name="Summary"
											>
												<ExcelColumn label="Category" value="category" />
												<ExcelColumn label="Cong" value="cong" />
												<ExcelColumn label="Region" value="region" />
												<ExcelColumn label="Total" value="total" />
											</ExcelSheet>
										</ExcelFile>
										<br />
										<br />

										<Title fontSize={15}>Statement</Title>
										<Table
											scroll={{ x: 1500 }}
											columns={bankStatementColumns}
											dataSource={bankStatementData.data}
											onChange={handleChange}
										/>
										<br />
										<br />

										<Title fontSize={15}>Summary</Title>
										<Table
											columns={summaryColumn}
											dataSource={bankStatementData.summary}
											onChange={handleChange}
										/>
									</>
								) : (
									<Box>
										<Button
											loading={loading}
											onClick={() => {
												setLoading(true);
												setBankStatementData(bankStatementTable());
												setLoading(false);
											}}
										>
											Process Bank Statment Extraction
										</Button>
									</Box>
								)
							) : (
								"You need to upload the members and the bank statement CSV in order to extract"
							)}
						</TabPane>
						<TabPane tab="Tithely" key="2">
							<Title fontSize={20}>Tithely</Title>

							{mems && mems.length > 0 && tithelies && tithelies.length > 0 ? (
								tithelyData &&
								tithelyData.data &&
								tithelyData.data.length > 0 ? (
									<>
										<ExcelFile
											element={
												<Button type="primary">
													Download {`GLY${tithelies[0].workdate}`}
												</Button>
											}
											filename={`GLY${tithelies[0].workdate}`}
										>
											<ExcelSheet data={tithelyData.data} name="Tithely">
												{/* <ExcelColumn label="Category" value="category" />
												<ExcelColumn label="EntityKey" value="entity" />
												<ExcelColumn label="Date" value="date" />
												<ExcelColumn label="Remarks" value="remarks" />
												<ExcelColumn label="Amount" value="amount" />
												<ExcelColumn label="ID" value="id" />
												<ExcelColumn label="Cong" value="cong" />
												<ExcelColumn label="Region" value="region" />
												<ExcelColumn label="Giving Type" value="type" />
												<ExcelColumn
													label="Check / iBank / NETS Number"
													value="checkIbankNets"
												/>
												<ExcelColumn label="Memo / Note" value="memo" />
												<ExcelColumn label="Backdate" value="backdate" /> */}

												<ExcelColumn label="Category" value="category" />
												<ExcelColumn label="EntityKey" value="entity" />
												<ExcelColumn label="Date" value="date" />
												<ExcelColumn label="For Period" value="remarks" />
												<ExcelColumn label="Amount" value="amount" />
												<ExcelColumn label="ID" value="id" />
												<ExcelColumn label="Cong" value="cong" />
												<ExcelColumn label="Region" value="region" />
												<ExcelColumn label="Giving Type" value="type" />
												<ExcelColumn label="Memo / Note" value="memo" />
												<ExcelColumn label="Backdate" value="backdate" />
												<ExcelColumn label="Remarks" value="blank" />
												<ExcelColumn label="Status" value="status" />
											</ExcelSheet>

											<ExcelSheet data={tithelyData.summary} name="Summary">
												<ExcelColumn label="Category" value="category" />
												<ExcelColumn label="Cong" value="cong" />
												<ExcelColumn label="Region" value="region" />
												<ExcelColumn label="Total" value="total" />
											</ExcelSheet>
										</ExcelFile>
										<br />
										<br />

										<Title fontSize={15}>Statement</Title>
										<Table
											scroll={{ x: 1500 }}
											columns={tithelyColumns}
											dataSource={tithelyData.data}
											onChange={handleChange}
										/>
										<br />
										<br />

										<Title fontSize={15}>Summary</Title>
										<Table
											columns={summaryColumn}
											dataSource={tithelyData.summary}
											onChange={handleChange}
										/>
									</>
								) : (
									<Box>
										<Button
											loading={loading}
											onClick={() => {
												setLoading(true);
												setTithelyData(tithelyTable());
												setLoading(false);
											}}
										>
											Process Tithely Extraction
										</Button>
									</Box>
								)
							) : (
								"You need to upload the members and the tithely CSV in order to extract"
							)}
						</TabPane>
					</Tabs>
				</Box>
			</Container>
		</View>
	);
};

const mapStateToProps = ({
	categories,
	exceptions,
	members,
	bankStatements,
	tithelies,
	inputs
}) => ({ categories, exceptions, members, bankStatements, tithelies, inputs });

export default connect(mapStateToProps, {})(Extraction);
