// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore/lite';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyAFoIIjSbP7ju8Ayro3nxfEwtMc7QcKauc',
  authDomain: 'hope-sg.firebaseapp.com',
  projectId: 'hope-sg',
  storageBucket: 'hope-sg.appspot.com',
  messagingSenderId: '101355626581',
  appId: '1:101355626581:web:369a1278f14705ee7634dc',
  measurementId: 'G-4FEBLQK3BF',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export default db;
