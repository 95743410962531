import { useEffect } from 'react';
import { connect } from 'react-redux';

const Middleware = ({ user, match, history }) => {
  useEffect(() => {
    if (!user.id && match.url !== '/login') {
      history.push('/login');
    }
  }, []);
  useEffect(() => {
    if (!user.id && match.url !== '/login') {
      history.push('/login');
    }
  }, [match]);

  return null;
};

const mapStateToProps = ({ user }) => {
  return { user };
};

export default connect(mapStateToProps, {})(Middleware);
