import React, { useEffect, useState } from 'react';
import { Container, View, Title } from '@common/components';
import { login } from '@resources/User/Actions';
import { Col, Form, Row, message, Input, Button } from 'antd';
import { connect } from 'react-redux';

const Login = ({ history, user, login }) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  useEffect(() => {
    if (user.id) {
      history.push('/dashboard');
    }
  }, []);

  const handleSubmit = async (values) => {
    setLoading(true);
    try {
      await login(values.name, values.password);
      message.success('You have successfully logged in!', 6);
      history.push('/dashboard');
    } catch (e) {
      message.error(
        'You have entered the wrong credentials. Please try again!',
        6
      );
    }
    setLoading(false);
  };

  return (
    <View>
      <Container>
        <Row>
          <Col span={8} offset={8}>
            <Title>Login</Title>
            <Form form={form} layout="vertical" onFinish={handleSubmit}>
              <Form.Item
                label="Username"
                name="name"
                rules={[
                  {
                    required: true,
                    message: 'Please input username',
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Password"
                name="password"
                rules={[
                  {
                    required: true,
                    message: 'Please input password',
                  },
                ]}
              >
                <Input.Password />
              </Form.Item>

              <Form.Item>
                <Button
                  loading={loading}
                  type="primary"
                  htmlType="submit"
                  className="login-form-button"
                >
                  Login
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Container>
    </View>
  );
};

const mapStateToProps = ({ user }) => ({
  user,
});

export default connect(mapStateToProps, { login })(Login);
