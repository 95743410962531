import {
  SET_CATEGORIES,
  SET_CATEGORY,
  ADD_CATEGORY,
  ADD_CATEGORIES,
  UPDATE_CATEGORY,
  REMOVE_CATEGORY,
  CLEAR_CATEGORIES,
  CLEAR_CATEGORY,
} from './ActionTypes';
import db from '@config/firestore';
import {
  collection,
  doc,
  addDoc,
  setDoc,
  getDoc,
  getDocs,
  deleteDoc,
} from 'firebase/firestore/lite';

const setCategories = (categories) => (dispatch) => {
  dispatch({
    type: SET_CATEGORIES,
    payload: categories,
  });
};

const setCategory = (category) => (dispatch) => {
  dispatch({
    type: SET_CATEGORY,
    payload: category,
  });
};

const addCategory = (category) => (dispatch) => {
  dispatch({
    type: ADD_CATEGORY,
    payload: category,
  });
};

const addCategories = (category) => (dispatch) => {
  dispatch({
    type: ADD_CATEGORIES,
    payload: category,
  });
};

const clearCategories = () => (dispatch) => {
  dispatch({
    type: CLEAR_CATEGORIES,
  });
};

const clearCategory = () => (dispatch) => {
  dispatch({
    type: CLEAR_CATEGORY,
  });
};

const fetchCategories = () => async (dispatch) => {
  let categories = [];
  try {
    const categoriesCol = collection(db, 'categories');
    const categorySnapshot = await getDocs(categoriesCol);
    categories = categorySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    dispatch({
      type: SET_CATEGORIES,
      payload: categories,
    });

    return Promise.resolve(categories);
  } catch (e) {
    console.log('fetchCategories', e);
    return Promise.reject(e);
  }
};

const fetchCategory = (id) => async (dispatch) => {
  try {
    const catRef = doc(db, 'categories', id);
    const categorySnapshot = await getDoc(catRef);

    dispatch({
      type: SET_CATEGORY,
      payload: categorySnapshot.data(),
    });

    return Promise.resolve(categorySnapshot);
  } catch (e) {
    console.log('fetchCategories', e);
    return Promise.reject(e);
  }
};

const createCategory = (data) => async (dispatch) => {
  try {
    const categoriesCol = collection(db, 'categories');
    const category = await addDoc(categoriesCol, data);

    dispatch({
      type: ADD_CATEGORY,
      payload: { ...data, id: category.id },
    });

    return Promise.resolve(category);
  } catch (e) {
    return Promise.reject(e);
  }
};

const updateCategory = (id, data) => async (dispatch) => {
  try {
    const category = await setDoc(doc(db, 'categories', id), data);

    dispatch({
      type: UPDATE_CATEGORY,
      payload: { ...data, id },
    });
    return Promise.resolve(category);
  } catch (e) {
    return Promise.reject(e);
  }
};

const deleteCategory = (category) => async (dispatch) => {
  try {
    const categoryDoc = doc(db, 'categories', category.id);
    await deleteDoc(categoryDoc);

    dispatch({
      type: REMOVE_CATEGORY,
      payload: category,
    });

    return Promise.resolve(category);
  } catch (e) {
    return Promise.reject(e);
  }
};

export {
  setCategories,
  setCategory,
  addCategory,
  addCategories,
  deleteCategory,
  updateCategory,
  clearCategories,
  clearCategory,
  createCategory,
  fetchCategory,
  fetchCategories,
};
