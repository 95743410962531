import dayjs from 'dayjs';
import {
  SET_TITHELIES,
  SET_TITHELY,
  ADD_TITHELY,
  ADD_TITHELIES,
  CLEAR_TITHELIES,
} from './ActionTypes';

const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

const setTithelies = (tithelies) => (dispatch) => {
  dispatch({
    type: SET_TITHELIES,
    payload: tithelies,
  });
};

const setTithely = (tithely) => (dispatch) => {
  dispatch({
    type: SET_TITHELY,
    payload: tithely,
  });
};

const addTithely = (tithely) => (dispatch) => {
  dispatch({
    type: ADD_TITHELY,
    payload: tithely,
  });
};

const addTithelies = (tithely) => (dispatch) => {
  dispatch({
    type: ADD_TITHELIES,
    payload: tithely,
  });
};

const clearTithelies = () => (dispatch) => {
  dispatch({
    type: CLEAR_TITHELIES,
  });
};

const parseTitheliesCSV = (tithelyCSV, workdate) => {
  const data = [];

  tithelyCSV &&
    tithelyCSV.length > 0 &&
    tithelyCSV.forEach((item, index) => {
      if (index > 0 && item.data[0]) {
        const dat = item.data[24]
          ? item.data[24].indexOf('-') > -1
            ? item.data[24].split('-')
            : item.data[24].split('/')
          : [1970, 1, 1];
        data.push({
          workdate,
          lastName: item.data[9],
          firstName: item.data[8],
          email: item.data[10],
          type: item.data[17],
          amount:
            item.data[5] === 'Yes'
              ? item.data[4]
                ? parseFloat(item.data[4].replace(/,/g, '').replace('$', ''))
                : 0
              : item.data[2]
              ? parseFloat(item.data[2].replace(/,/g, '').replace('$', ''))
              : 0,

          id: item.data[0],
          memberID: item.data[7],
          date: item.data[24]
            ? item.data[24].indexOf('-') > -1
              ? `${dat[2]}/${months[dat[1] - 1]}/${dat[0]}`
              : `${dat[0]}/${months[dat[1] - 1]}/${dat[2]}`
            : dayjs().format('DD/MMM/YYYY'),
          memo: item.data[19],
          checkIbankNets: item.data[18],
          backdate: item.data[26],
        });
      }
    });

  return data;
};

export {
  setTithelies,
  setTithely,
  addTithely,
  addTithelies,
  clearTithelies,
  parseTitheliesCSV,
};
