import React, { useCallback, useEffect, useState } from 'react';
import { Box, Container, View, Title } from '@common/components';
import {
  setMembers,
  clearMembers,
  parseMembersCSV,
} from '@resources/Member/Actions';
import { connect } from 'react-redux';
import { CSVReader } from 'react-papaparse';
import { Button, Table } from 'antd';
import MemberResult from './Fragments/Result';
import { getColumnSearchProps, getColumnSortProps } from '@common/utils/table';

const Member = ({ history, members, setMembers, clearMembers }) => {
  const [sortedInfo, setSortedInfo] = useState({});
  const handleChange = useCallback(
    (pagination, filters, sorter) => {
      setSortedInfo(sorter);
    },
    [setSortedInfo]
  );
  const handleSearch = useCallback((selectedKeys, confirm) => {
    confirm();
  }, []);

  const handleReset = useCallback((clearFilters) => {
    clearFilters();
  }, []);
  useEffect(() => {}, []);

  const checkMemberData = (data) => {
    setMembers(parseMembersCSV(data));
  };

  const columns = [
    {
      title: 'Last Name',
      dataIndex: 'lastName',
      key: 'lastName',
      ...getColumnSortProps({
        index: 'lastName',
        type: 'text',
        sortedInfo,
      }),
      ...getColumnSearchProps({
        index: 'lastName',
        handleSearch,
        handleReset,
      }),
    },
    {
      title: 'First Name',
      dataIndex: 'firstName',
      key: 'firstName',
      ...getColumnSortProps({
        index: 'firstName',
        type: 'text',
        sortedInfo,
      }),
      ...getColumnSearchProps({
        index: 'firstName',
        handleSearch,
        handleReset,
      }),
    },
    {
      title: 'Member ID',
      dataIndex: 'memberID',
      key: 'memberID',
      ...getColumnSortProps({
        index: 'memberID',
        type: 'text',
        sortedInfo,
      }),
      ...getColumnSearchProps({
        index: 'memberID',
        handleSearch,
        handleReset,
      }),
    },
    {
      title: 'Congregation',
      dataIndex: 'congregation',
      key: 'congregation',
      ...getColumnSortProps({
        index: 'congregation',
        type: 'text',
        sortedInfo,
      }),
      ...getColumnSearchProps({
        index: 'congregation',
        handleSearch,
        handleReset,
      }),
    },
    {
      title: 'Region',
      dataIndex: 'region',
      key: 'region',
      ...getColumnSortProps({
        index: 'region',
        type: 'text',
        sortedInfo,
      }),
      ...getColumnSearchProps({
        index: 'region',
        handleSearch,
        handleReset,
      }),
    },
  ];

  return (
    <View>
      <Container>
        <Box flex>
          <Title>Members</Title>

          {members && members.length > 0 ? (
            <>
              <MemberResult cols={6} />
              <Table
                columns={columns}
                dataSource={members}
                onChange={handleChange}
              />
              <Box my={50}>
                <Button
                  style={{ width: '100%' }}
                  danger
                  onClick={() => {
                    clearMembers();
                  }}
                >
                  Clear Members Data
                </Button>
                <br />
                <br />
                <br />
              </Box>
            </>
          ) : (
            <>
              <Box fontSize={10} textAlign="center">
                CLICK OR DRAG & DROP FILE
              </Box>
              <CSVReader onDrop={(data) => checkMemberData(data)}>
                Upload Member CSV
              </CSVReader>
            </>
          )}
        </Box>
      </Container>
    </View>
  );
};

const mapStateToProps = ({ members }) => ({
  members,
});

export default connect(mapStateToProps, { setMembers, clearMembers })(Member);
