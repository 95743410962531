import {
  SET_EXCEPTIONS,
  SET_EXCEPTION,
  CLEAR_EXCEPTIONS,
  CLEAR_EXCEPTION,
  ADD_EXCEPTION,
  UPDATE_EXCEPTION,
  REMOVE_EXCEPTION,
} from './ActionTypes';

export const exception = (state = {}, exception) => {
  switch (exception.type) {
    case SET_EXCEPTION:
      return exception.payload;
    case UPDATE_EXCEPTION:
      if (
        state &&
        state.id &&
        state.id.toString() !== exception.payload.id.toString()
      ) {
        return state;
      }

      // Otherwise, this is the one we want - return an updated value
      return {
        ...state,
        ...exception.payload,
      };
    case CLEAR_EXCEPTION:
      return {};
    default:
      return state;
  }
};

export default (state = [], exception) => {
  switch (exception.type) {
    case SET_EXCEPTIONS:
      return exception.payload;
    case ADD_EXCEPTION:
      return [...state, exception.payload];
    case UPDATE_EXCEPTION:
      return state.map((item) => {
        if (
          item &&
          item.id &&
          item.id.toString() !== exception.payload.id.toString()
        ) {
          return item;
        }

        // Otherwise, this is the one we want - return an updated value
        return {
          ...item,
          ...exception.payload,
        };
      });
    case REMOVE_EXCEPTION:
      return state.filter((item) => {
        return item.id !== exception.payload.id;
      });
    case CLEAR_EXCEPTIONS:
      return [];
    default:
      return state;
  }
};
