import {
  SET_INPUTS,
  SET_INPUT,
  ADD_INPUT,
  ADD_INPUTS,
  CLEAR_INPUTS,
  REMOVE_INPUT,
} from './ActionTypes';
import db from '@config/firestore';
import {
  collection,
  doc,
  addDoc,
  deleteDoc,
  getDocs,
} from 'firebase/firestore/lite';

const setInputs = (inputs) => (dispatch) => {
  dispatch({
    type: SET_INPUTS,
    payload: inputs,
  });
};

const setInput = (input) => (dispatch) => {
  dispatch({
    type: SET_INPUT,
    payload: input,
  });
};

const addInput = (input) => (dispatch) => {
  dispatch({
    type: ADD_INPUT,
    payload: input,
  });
};

const addInputs = (input) => (dispatch) => {
  dispatch({
    type: ADD_INPUTS,
    payload: input,
  });
};

const clearInputs = () => (dispatch) => {
  dispatch({
    type: CLEAR_INPUTS,
  });
};

const fetchInputs = () => async (dispatch) => {
  let inputs = [];
  try {
    const inputsCol = collection(db, 'inputs');
    const inputSnapshot = await getDocs(inputsCol);
    inputs = inputSnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));

    dispatch({
      type: SET_INPUTS,
      payload: inputs,
    });

    return Promise.resolve(inputs);
  } catch (e) {
    console.log('fetchInputs', e);
    return Promise.reject(e);
  }
};

const createInput = (data) => async (dispatch) => {
  try {
    const inputsCol = collection(db, 'inputs');
    const input = await addDoc(inputsCol, data);

    dispatch({
      type: ADD_INPUT,
      payload: { ...data, id: input.id },
    });

    return Promise.resolve({ ...data, id: input.id });
  } catch (e) {
    return Promise.reject(e);
  }
};

const deleteInput = (input) => async (dispatch) => {
  try {
    const inputDoc = doc(db, 'inputs', input.id);
    await deleteDoc(inputDoc);

    dispatch({
      type: REMOVE_INPUT,
      payload: input,
    });

    return Promise.resolve(input);
  } catch (e) {
    return Promise.reject(e);
  }
};

export {
  setInputs,
  setInput,
  addInput,
  addInputs,
  clearInputs,
  createInput,
  deleteInput,
  fetchInputs,
};
