import {
  SET_USERS,
  SET_USER,
  CLEAR_USERS,
  CLEAR_USER,
  ADD_USER,
  UPDATE_USER,
  REMOVE_USER,
} from './ActionTypes';

export const user = (state = {}, category) => {
  switch (category.type) {
    case SET_USER:
      return category.payload;
    case UPDATE_USER:
      if (state.id.toString() !== category.payload.id.toString()) {
        return state;
      }

      // Otherwise, this is the one we want - return an updated value
      return {
        ...state,
        ...category.payload,
      };
    case CLEAR_USER:
      return {};
    default:
      return state;
  }
};

export default (state = [], category) => {
  switch (category.type) {
    case SET_USERS:
      return category.payload;
    case ADD_USER:
      return [...state, category.payload];
    case UPDATE_USER:
      return state.map((item) => {
        if (item.id !== category.payload.id) {
          return item;
        }

        // Otherwise, this is the one we want - return an updated value
        return {
          ...item,
          ...category.payload,
        };
      });
    case REMOVE_USER:
      return state.filter((item) => {
        return item.id !== category.payload.id;
      });
    case CLEAR_USERS:
      return [];
    default:
      return state;
  }
};
