import React from 'react';
import { connect } from 'react-redux';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import { Transition, TransitionGroup } from 'react-transition-group';
import { play, exit } from '@common/timelines';
import { Navbar } from '@fragments';

import ScrollToTop from '@common/fragments/ScrollToTop';
import { setLocalSubscriptions } from '@common/resources/Subscription/actions';

import BankStatement from '../BankStatement';
import { Category, CategoryForm } from '../Category';
import { Exception, ExceptionForm } from '../Exception';
import Dashboard from '../Dashboard';
import Extraction from '../Extraction';
import Member from '../Member';
import Tithely from '../Tithely';

import Login from '../Login';

import Middleware from './Middleware';

const Base = ({ dispatch, setLocalSubscriptions, ...restProps }) => {
  let basename = '';

  return (
    <BrowserRouter basename={basename}>
      <ScrollToTop>
        <Route path="*" component={Navbar} {...restProps} />
        <Route path="*" component={Middleware} {...restProps} />
        <Route
          render={({ location }) => {
            const { pathname, key } = location;

            return (
              <TransitionGroup component={null}>
                <Transition
                  key={key}
                  appear={true}
                  onEnter={(node, appears) => {
                    if (node) {
                      return play(pathname, node, appears);
                    }
                  }}
                  onExit={(node, appears) => {
                    if (node) {
                      return exit(node, appears);
                    }
                  }}
                  timeout={{ enter: 150, exit: 150 }}
                >
                  <Switch location={location}>
                    <Route
                      path="/"
                      exact
                      component={Dashboard}
                      {...restProps}
                    />
                    <Route
                      exact
                      path="/categories"
                      component={Category}
                      {...restProps}
                    />

                    <Route
                      exact
                      path="/categories/create"
                      component={CategoryForm}
                      {...restProps}
                    />

                    <Route
                      exact
                      path="/categories/update/:id"
                      component={CategoryForm}
                      {...restProps}
                    />

                    <Route
                      exact
                      path="/exceptions"
                      component={Exception}
                      {...restProps}
                    />

                    <Route
                      exact
                      path="/exceptions/create"
                      component={ExceptionForm}
                      {...restProps}
                    />

                    <Route
                      exact
                      path="/exceptions/update/:id"
                      component={ExceptionForm}
                      {...restProps}
                    />

                    <Route
                      exact
                      path="/members"
                      component={Member}
                      {...restProps}
                    />

                    <Route
                      exact
                      path="/login"
                      component={Login}
                      {...restProps}
                    />

                    <Route
                      exact
                      path="/bank-statements"
                      component={BankStatement}
                      {...restProps}
                    />

                    <Route
                      exact
                      path="/tithelies"
                      component={Tithely}
                      {...restProps}
                    />

                    <Route
                      exact
                      path="/extraction"
                      component={Extraction}
                      {...restProps}
                    />

                    <Route
                      path="*"
                      render={() => {
                        return <Redirect to="/" />;
                      }}
                    />
                  </Switch>
                </Transition>
              </TransitionGroup>
            );
          }}
        />
      </ScrollToTop>
    </BrowserRouter>
  );
};

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps, { setLocalSubscriptions })(Base);
