import bankStatements, {
  bankStatement,
} from '@resources/BankStatement/Reducers';
import categories, { category } from '@resources/Category/Reducers';
import exceptions, { exception } from '@resources/Exception/Reducers';
import inputs, { input } from '@resources/Input/Reducers';
import members, { member } from '@resources/Member/Reducers';
import tithelies, { tithely } from '@resources/Tithely/Reducers';
import users, { user } from '@resources/User/Reducers';

export const reducers = {
  bankStatement,
  bankStatements,
  category,
  categories,
  exception,
  exceptions,
  input,
  inputs,
  member,
  members,
  tithely,
  tithelies,
  user,
  users,
};
