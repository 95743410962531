import React from 'react';
import { Box, Text } from '@common/components';
import { connect } from 'react-redux';
import { Col, Row } from 'antd';

const TithelyResult = ({ cols = 24, tithelies }) => {
  const totalAmount = () => {
    let value = 0;
    tithelies &&
      tithelies.forEach((bs) => {
        if (bs.amount) {
          value += parseInt(bs.amount);
        }
      });

    return value;
  };

  return (
    <Box flex>
      <Row>
        <Col xs={cols}>
          <Text fontSize={10} html="TOTAL RECORD" />
          {tithelies ? tithelies.length : 0}
          <br />
          <br />
        </Col>
        <Col xs={cols}>
          <Text fontSize={10} html="TOTAL AMOUNT" />
          {tithelies ? totalAmount() : 0}
          <br />
          <br />
        </Col>
      </Row>
    </Box>
  );
};

const mapStateToProps = ({ tithelies }) => ({
  tithelies,
});

export default connect(mapStateToProps, {})(TithelyResult);
