import React from 'react';
import { Box, Text } from '@common/components';
import { connect } from 'react-redux';
import { Col, Row } from 'antd';

const MemberResult = ({ history, cols = 24, bankStatements }) => {
  const totalCredit = () => {
    let value = 0;
    bankStatements &&
      bankStatements.forEach((bs) => {
        if (bs.credit) {
          value += parseInt(bs.credit);
        }
      });

    return value;
  };

  return (
    <Box flex>
      <Row>
        <Col xs={cols}>
          <Text fontSize={10} html="TOTAL STATEMENT" />
          {bankStatements ? bankStatements.length : 0}
          <br />
          <br />
        </Col>
        <Col xs={cols}>
          <Text fontSize={10} html="TOTAL CREDIT" />
          {bankStatements ? totalCredit() : 0}
          <br />
          <br />
        </Col>
      </Row>
    </Box>
  );
};

const mapStateToProps = ({ bankStatements }) => ({
  bankStatements,
});

export default connect(mapStateToProps, {})(MemberResult);
